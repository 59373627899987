.animated-icon1, .animated-icon2, .animated-icon3 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    }
    
    .animated-icon1 span, .animated-icon2 span, .animated-icon3 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    }
    
    .animated-icon1 span {
    background: #e65100;
    }
    
    .animated-icon2 span {
    background: #252525;
    }
    
    .animated-icon3 span {
    background: #f3e5f5;
    }
    
    .animated-icon1 span:nth-child(1) {
    top: 0px;
    }
    
    .animated-icon1 span:nth-child(2) {
    top: 10px;
    }
    
    .animated-icon1 span:nth-child(3) {
    top: 20px;
    }
    
    .animated-icon1.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    }
    
    .animated-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
    }
    
    .animated-icon1.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    }
    
    /* Icon 3*/
    
    .animated-icon2 span:nth-child(1) {
    top: 0px;
    }
    
    .animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) {
    top: 10px;
    }
    
    .animated-icon2 span:nth-child(4) {
    top: 20px;
    }
    
    .animated-icon2.open span:nth-child(1) {
    top: 11px;
    width: 0%;
    left: 50%;
    }
    
    .animated-icon2.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    }
    
    .animated-icon2.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    }
    
    .animated-icon2.open span:nth-child(4) {
    top: 11px;
    width: 0%;
    left: 50%;
    }
    
    /* Icon 4 */
    
    .animated-icon3 span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    }
    
    .animated-icon3 span:nth-child(2) {
    top: 10px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    }
    
    .animated-icon3 span:nth-child(3) {
    top: 20px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    }
    
    .animated-icon3.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0px;
    left: 8px;
    }
    
    .animated-icon3.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
    }
    
    .animated-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 21px;
    left: 8px;
    }

    