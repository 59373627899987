/* CSS por defecto del Widget. Se debe utilizar !important si se desea sobreescribir algún valor */

/****************************** CSS for credit card ************************/
.card-ui-component-field {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.card-ui-component-ddl-expiry {
  display: block;
  width: 40% !important;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  float: left;
  margin-right: 10px;
}
.card-ui-component-card-bg {
  padding: 6px 0px;
  float: right;
  width: auto;
  position: absolute;
  top: -5px;
  right: 0;
}
ul.card-ui-component-cctypes li {
  background-image: url("http://apicomponent.test.merchantprocess.net/Images/cctypes.png");
  background-size: 200% 200%;
  background-position: 0 0;
  float: left;
  height: 29px;
  margin-right: 0px;
  text-indent: 9999px;
  transition: all 0.2s ease 0s;
  width: 40px;
}
ul.card-ui-component-cctypes {
  list-style: none outside none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  margin-top: 2px;
  margin-right: 3px;
}
.card-ui-component-cctypes .card-ui-component-visa {
  background-position: 120px -29px;
}
.card-ui-component-cctypes .card-ui-component-visa.on {
  background-position: -120px 0px;
}
.card-ui-component-cctypes .card-ui-component-mastercard {
  background-position: 0 29px;
}
.card-ui-component-cctypes .card-ui-component-mastercard.on {
  background-position: 0 0;
}
.card-ui-component-heading {
  color: #fff;
  background-color: #a95a5a !important;
  border-color: #bfbfbf !important;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 1px !important;
  border-top-right-radius: 1px !important;
}
.card-ui-component-outer {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  width: 100% !important;
  margin: 0 auto;
}
.card-ui-component-inner-body {
  background-color: #f1f1f1 !important;
  padding: 15px 25px !important;
}

.card-ui-component-label-style {
  width: 100% !important;
  float: left;
  padding-top: 7px;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
.card-ui-component-input-style {
  width: 100% !important;
  float: right;
  position: relative;
}
.card-ui-component-input-style :focus {
  border: 1px solid #2e6da4;
}
.card-ui-component-validation-error {
  border-color: red;
}
.card-ui-component-row-static {
  width: 100%;
  margin-bottom: 5px;
  display: inline-block;
}
.card-ui-component-save-btn {
  color: #fff;
  background-color: #a95a5a !important;
  border-color: #5f3232;
}
.card-ui-component-save-btn:hover {
  color: #fff;
  background-color: #964e4e !important;
  border-color: #5f3232;
}
.card-ui-component-cancel-btn {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.card-ui-component-cancel-btn:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.card-ui-component-btn-action {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.card-ui-component-action-container {
  text-align: center;
}
.border-line {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
.card-ui-component-select-input {
  width: 10%;
}
.card-ui-component-alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.card-ui-component-danger-alert {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  border-radius: 0;
}
.card-ui-component-success-alert {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
