canvas{
    display:block;
    /*vertical-align: bottom;*/
  }
  
  
  /* ---- stats.js ---- */
  
 
  
  .js-count-particles{
    font-size: 1.1em;
  }
  
  #stats,
  .count-particles{
    -webkit-user-select: none;
    margin-top: 5px;
    margin-left: 5px;
  }
  
  #stats{
    border-radius: 3px 3px 0 0;
    overflow: hidden;
  }
  
  .count-particles{
    border-radius: 0 0 3px 3px;
  }
  
  
  /* ---- particles.js container ---- */
  
  #particles-js{
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
    background-color: #b6b6b6d8;
  }