/*
@File: StartP Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

==== INDEX PAGE CSS

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - Boxes CSS
** - About CSS
** - Why Choose Us CSS
** - Services CSS
** - Services Details CSS
** - Features CSS
** - Features Details CSS
** - Team CSS
** - Works CSS
** - Fun Facts CSS
** - Pricing CSS
** - Calendario CSS
** - WidgetB CSS
** - Domain Search CSS
** - Feedback CSS
** - Ready To Talk CSS
** - Partner CSS
** - Blog CSS
** - Shop CSS
** - Shop Details CSS
** - Cart CSS
** - Checkout CSS
** - Page Title CSS
** - FAQ CSS
** - Pagination CSS
** - Blog Details CSS
** - Sidebar Widget CSS
** - Project Details CSS
** - Contact CSS
** - Coming Soon CSS
** - 404 Error CSS
** - CTA CSS
** - Repair Main Banner CSS
** - Repair Partner CSS
** - Repair About CSS
** - Repair Services CSS
** - Repair CTA CSS
** - Repair Team CSS
** - Repair Why Choose Us CSS
** - Repair Feedback CSS
** - IoT Main Banner CSS
** - IoT Partner CSS
** - Iot Services CSS
** - IoT CTA CSS
** - IoT Features CSS
** - IoT Team CSS
** - IoT Why Choose Us CSS
** - ML Main Banner CSS
** - ML Partner CSS
** - Our Solutions CSS
** - ML About CSS
** - ML Services CSS
** - ML Feedback CSS
** - Free Trial CSS
** - ML Projects CSS
** - Agency Main Banner CSS
** - Our Solutions CSS
** - Agency About CSS
** - Agency Services CSS
** - Testimonials CSS
** - Agency CTA CSS
** - Agency Portfolio Main Banner CSS
** - Our Latest Projects CSS
** - Agency Portfolio About CSS
** - Agency Portfolio Partner CSS
** - Creative Area CSS
** - Footer CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/

@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

body {
    font-family: "Arial", "Poppins", sans-serif;
    padding: 0;
    margin: 0;
    font-size: 15px;
    background-image: url("https://res.cloudinary.com/du4k7xyix/image/upload/v1590688784/Sigma/default/manos_uzvdxf.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #d6d4d4
        /*#212f54*/
    ;
}

img {
    max-width: 100%;
}

.bloque-espacio-top {
    margin-top: 12.5vh;
}

.bloque-espacio-bottom {
    margin-bottom: 12.5vh;
}

a {
    color: #181818;
    display: inline-block;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none;
    outline: 0 !important;
    letter-spacing: 0.1em;
}

a:hover, a:focus {
    color: #981914
        /*#bc2424*/
    ;
    /*#44ce6f*/
    text-decoration: none;
}

.d-table {
    width: 100%;
    height: 100%;
}

.photo {
    background-image: url(https://res.cloudinary.com/du4k7xyix/image/upload/v1703714172/Sigma/Logos/Sigma_Logo_2_brrt8w.png);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    display: block;
    width: 100px;
    height: 60px;
}

.logoFac {
    background-image: url(https://res.cloudinary.com/du4k7xyix/image/upload/v1703697420/Sigma/Logos/Facturelo_Logo_zjawoq.png);
    background-size: 300px 100px;
    background-repeat: no-repeat;
    display: inline-block;
    width: 300px;
    height: 100px;
}

.logoSig {
    background-image: url(https://res.cloudinary.com/du4k7xyix/image/upload/v1703712865/Sigma/Logos/Sigma_Logo_j92a1y.png);
    background-size: 200px 100px;
    background-repeat: no-repeat;
    display: inline-flex;
    width: 200px;
    height: 100px;
}

.d-table-cell {
    vertical-align: middle;
}

p {
    /*STYLEPARRAFO*/
    color: #474747;
    line-height: 1.9;
    letter-spacing: 0.1em;
}

p:last-child {
    margin-bottom: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #981914;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.ptb-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

/*
.bg-f7fafd {
  background-color: #bebebe;
}

.bg-f9f6f6 {
  background-color: #f9f6f68f;
}

.bg-f9fafb {
  background-color: #f9fafb83;
}

.bg-0f054b {
  background-color: #0f054b;
}
*/

/*owl carousel btn*/

.owl-theme .owl-nav {
    margin-top: 0;
}

.owl-theme .owl-dots {
    margin-top: 20px;
    line-height: 0.01;
}

.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 0 5px;
    background: #d6d6d6;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span {
    background: #bc2424;
    /*#44ce6f*/
}

/*btn btn-primary*/
.dt-buttons {
    display: inline-block;
}

.btn-primary, .dt-button {
    color: #ffffff;
    background-color:
        /*#c03838*/
        #981914;
    /*#44ce6f*/
    -webkit-box-shadow: 0 13px 27px 0 rgba(206, 68, 68, 0.25);
    box-shadow: 0 13px 27px 0 rgba(206, 68, 68, 0.25);
}

.btn-primary:focus .dt-button:focus {
    color:
        /*#c03838*/
        #981914;
    background: #e6191900;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 13px 27px 0 rgba(227, 121, 121, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.btn-primary:hover, .dt-button:hover {
    color: #ffffff;
    background: #981914;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(227, 121, 121, 0.25);
}

.btn-primary.focus, .btn-primary:focus, .dt-button:focus {
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary .show>.btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary .show>.btn-primary.dropdown-toggle {
    color: #ffffff;
    background: #d45656;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #d45656;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    opacity: 1;
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary:disabled:hover, .btn-primary:disabled:focus {
    background: #ac5d5d;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.btn, .dt-button {
    font-weight: 600;
    font-size: 14px;
    border: none;
    padding: 15px 40px;
    position: relative;
    border-radius: 4px;
    z-index: 1;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #bc2424;
}

.btn::after, .btn::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: #d45656;
    z-index: -1;
}

.btn::after {
    left: 0;
    border-radius: 4px 0 0 4px;
}

.btn::before {
    right: 0;
    border-radius: 0 4px 4px 0;
}

.btn:hover::after, .btn:hover::before {
    width: 50%;
}

/*btn-secondary*/

.btn.btn-secondary {
    text-transform: capitalize;
    border-radius: 0;
    background-color: #bc2424;
    /*#44ce6f*/
    padding: 13px 30px;
    -webkit-box-shadow: 5px 5px 5px #f5a6a6;
    box-shadow: 5px 5px 5px #f5a6a6;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}

.btn.btn-secondary::before {
    border-radius: 0;
    background: #c679e3;
}

.btn.btn-secondary::after {
    border-radius: 0;
    background: #c679e3;
}

.btn.btn-secondary:not(:disabled):not(.disabled).active, .btn.btn-secondary:not(:disabled):not(.disabled):active, .show>.btn.btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #bc2424;
    /*#44ce6f*/
    border-color: #bc2424;
    /*#44ce6f*/
}

.btn.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn.btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 5px 5px 5px #e9caf5;
    box-shadow: 5px 5px 5px #e9caf5;
}

.btn.btn-secondary:focus {
    color: #ffffff;
}

.btn.btn-secondary:hover, .btn.btn-secondary:focus {
    -webkit-box-shadow: 5px 5px 5px #e9caf5;
    box-shadow: 5px 5px 5px #e9caf5;
}

/*form control*/

.form-group {
    margin-bottom: 18px;
}

label {
    color: #0e314c;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

.form-control {
    height: 45px;
    padding: 10px;
    font-size: 14px;
    color: #0e314c;
    border: 1px solid #eeeeee;
    border-radius: 0;
    background: #eeeeee;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.form-control:disabled {
    color: #091e2e;
    background-color: #b3b3b3;
    border-color: #7d7d7d;
}

.form-control:focus {
    outline: 0;
    background: #ffffff;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    border-color: #bc2424;
    /*#44ce6f*/
}

.input-error {
    border-color: #ff8888;
}

/*Section Title*/

.section-title {
    text-align: center;
    margin-bottom: 60px;
    color: rgb(255, 255, 255);
}

.section-title h2 {
    margin-bottom: 0;
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
}

.section-title p {
    max-width: 520px;
    margin: 0 auto;
    color: #ffffff;
}

.section-title .bar {
    height: 5px;
    width: 90px;
    background: #d3a3a3;
    margin: 20px auto;
    position: relative;
    border-radius: 30px;
}

.section-title .bar::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #bc2424;
    /*#44ce6f*/
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}

.section-title .sub-title {
    display: inline-block;
    color:
        /*#c03838*/
        #981914;
    background-color: #f5dffd;
    margin-bottom: 18px;
    border-radius: 30px;
    padding: 5px 20px 4px;
    font-size: 13px;
}

.section-title.text-left h2 {
    font-weight: 500;
}

.section-title.text-left h2 span {
    display: inline-block;
    font-weight: 700;
}

.section-title.text-left p {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
}

@-webkit-keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    to {
        -webkit-transform: translateX(88px);
        transform: translateX(88px);
    }
}

@keyframes MOVE-BG {
    from {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    to {
        -webkit-transform: translateX(88px);
        transform: translateX(88px);
    }
}

/*================================================
Preloader CSS
=================================================*/

.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: #44ce6f;
}

.preloader .spinner {
    width: 60px;
    height: 60px;
    background-color: #ffffff;
    margin: 0 auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
    position: absolute;
    right: 0;
    left: 0;
    -webkit-box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.4);
    top: 50%;
    margin-top: -75px;
}

@-webkit-keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@keyframes sk-rotateplane {
    0% {
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/*================================================
Navbar CSS
=================================================*/

.startp-responsive-nav {
    display: none;
}

#header.headroom {
    width: 100%;
    z-index: 990;
    top: 0;
    left: 0;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff !important;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding-top: 15px;
    padding-bottom: 15px;
}

.startp-nav {
    background: transparent;
    position: relative;
    height: auto;
    width: 100%;
}

.startp-nav nav {
    padding: 0;
}

.startp-nav nav ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.startp-nav nav .navbar-nav .nav-item {
    position: relative;
    padding: 15px 0;
}

.startp-nav nav .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 15px;
    color: #4a6f8a;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 14px;
    margin-right: 14px;
}

.startp-nav nav .navbar-nav .nav-item a:hover, .startp-nav nav .navbar-nav .nav-item a:focus, .startp-nav nav .navbar-nav .nav-item a.active {
    color: #bc2424;
    /*#44ce6f*/
}

.startp-nav nav .navbar-nav .nav-item a svg {
    width: 14px;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
    background: #ffffff;
    position: absolute;
    top: 80px;
    left: 0;
    width: 250px;
    z-index: 90;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    opacity: 0;
    border-radius: 5px;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li {
    position: relative;
    padding: 0;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li a {
    font-size: 14px;
    font-weight: 500;
    padding: 9px 15px;
    margin: 0;
    display: block;
    color: #4a6f8a;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li a:hover, .startp-nav nav .navbar-nav .nav-item .dropdown_menu li a:focus, .startp-nav nav .navbar-nav .nav-item .dropdown_menu li a.active {
    color: #bc2424;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu {
    position: absolute;
    left: 220px;
    top: 0;
    opacity: 0 !important;
    visibility: hidden !important;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a {
    color: #4a6f8a;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a:hover, .startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a:focus, .startp-nav nav .navbar-nav .nav-item .dropdown_menu li .dropdown_menu li a.active {
    color: #bc2424;
    /*#44ce6f*/
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li:hover .dropdown_menu {
    opacity: 1 !important;
    visibility: visible !important;
    top: -20px !important;
}

.startp-nav nav .navbar-nav .nav-item .dropdown_menu li:hover a {
    color: #bc2424;
    /*#44ce6f*/
}

.startp-nav nav .navbar-nav .nav-item:hover ul {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.startp-nav nav .others-option {
    margin-left: 20px;
}

.startp-nav nav .others-option .cart-wrapper-btn {
    position: relative;
    margin-right: 20px;
}

.startp-nav nav .others-option .cart-wrapper-btn svg {
    width: 15px;
}

.startp-nav nav .others-option .cart-wrapper-btn span {
    display: inline-block;
    background-color: red;
    color: #ffffff;
    font-size: 9px;
    width: 13px;
    height: 13px;
    text-align: center;
    line-height: 14px;
    border-radius: 50%;
    position: relative;
    top: -7px;
    right: 4px;
}

.startp-nav nav .others-option .btn.btn-light {
    background: transparent;
    border: 2px dashed
        /*#c03838*/
        #981914;
    padding: 12px 33px;
    margin-right: 10px;
    color:
        /*#c03838*/
        #981914;
}

.startp-nav nav .others-option .btn.btn-light:focus {
    color: #094879;
}

.startp-nav nav .others-option .btn.btn-light:hover {
    color: #ffffff;
    border-color: #bc2424;
    /*#44ce6f*/
}

.startp-nav nav .others-option .btn.btn-light::after, .startp-nav nav .others-option .btn.btn-light::before {
    background: #bc2424;
    /*#44ce6f*/
}

.startp-nav nav .others-option .btn.btn-primary {
    background: #0e314c;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.startp-nav nav .others-option .btn.btn-primary::after, .startp-nav nav .others-option .btn.btn-primary::before {
    background: #bc2424;
    /*#44ce6f*/
    -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
}

.navbar-style-three .startp-nav {
    padding-left: 25px;
    padding-right: 25px;
}

@media only screen and (max-width: 990px) {
    #header {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .startp-nav nav .others-option .btn.btn-light {
        padding: 10px;
        font-size: 80%;
    }
}

@media only screen and (max-width: 767px) {
    .startp-nav nav .others-option {
        padding-top: 10px;
        width: 100%;
    }
}

/*================================================
Main Banner CSS
=================================================*/

.main-banner {
    position: relative;
    height: 910px;
    z-index: 1;
    background: transparent url(../images/img/banner-bg1.jpg) right top no-repeat;
}

.main-banner .hero-content h1 {
    font-size: 45px;
    font-weight: 500;
    margin-bottom: 20px;
}

.main-banner .hero-content p {
    margin-bottom: 30px;
}

.main-banner .banner-form {
    background: #ffffff;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 50px 30px;
    border-radius: 5px;
}

.main-banner .banner-form form .form-control {
    padding-left: 15px;
}

.main-banner .banner-form form .btn {
    display: block;
    width: 100%;
    -webkit-transform: unset;
    transform: unset;
    margin-top: 25px;
}

.main-banner .banner-image {
    position: relative;
}

.main-banner .banner-image img {
    position: absolute;
}

.main-banner .banner-image img:nth-child(1) {
    z-index: 3;
    top: -130px;
    left: -45px;
}

.main-banner .banner-image img:nth-child(2) {
    right: 145px;
    top: -287px;
}

.main-banner .banner-image img:nth-child(3) {
    left: 25px;
    top: -20px;
}

.main-banner .banner-image img:nth-child(4) {
    right: 12%;
    top: 118px;
    z-index: 1;
}

.main-banner .banner-image img:nth-child(5) {
    left: 28%;
    top: -120px;
    z-index: 2;
}

.main-banner .banner-image img:nth-child(6) {
    right: 35%;
    z-index: 1;
    top: -150px;
}

.main-banner .banner-image img:nth-child(7) {
    right: 20px;
    top: -110px;
}

.main-banner .banner-image img:nth-child(8) {
    right: 25px;
    top: -140px;
}

.main-banner .banner-image img:nth-child(9) {
    right: 30%;
    z-index: 1;
    top: -35px;
}

.main-banner .banner-image img:nth-child(10) {
    left: 37%;
    top: -28px;
    z-index: 1;
}

.main-banner .banner-image img:nth-child(11) {
    left: 37%;
    top: -160px;
    z-index: 1;
}

.main-banner .banner-image img:nth-child(12) {
    left: 90px;
    top: -110px;
}

.main-banner .banner-image img:nth-child(13) {
    left: 56%;
    top: -25px;
    z-index: 2;
}

.main-banner .banner-image img:nth-child(14) {
    right: 105px;
    top: -28px;
}

.main-banner .banner-image img:last-child {
    display: none;
}

.main-banner .banner-image.single-banner-image img {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hosting-main-banner {
    position: relative;
    z-index: 1;
    background-color: #032b8d;
    padding-top: 250px;
    padding-bottom: 190px;
}

.hosting-main-banner .hosting-banner-content h1 {
    font-size: 45px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 25px;
}

.hosting-main-banner .hosting-banner-content ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.hosting-main-banner .hosting-banner-content ul li {
    margin-bottom: 12px;
    color: #ffffff;
    position: relative;
    padding-left: 30px;
}

.hosting-main-banner .hosting-banner-content ul li::before {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
    height: 2px;
    background: #bc2424
        /*#44ce6f*/
    ;
    content: "";
}

.hosting-main-banner .hosting-banner-content ul li:last-child {
    margin-bottom: 0;
}

.hosting-main-banner .hosting-banner-content p {
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 25px;
}

.hosting-main-banner .hosting-banner-content p span {
    text-decoration: line-through;
}

.hosting-main-banner .hosting-banner-image {
    position: relative;
}

.hosting-main-banner .hosting-banner-image img {
    position: absolute;
}

.hosting-main-banner .hosting-banner-image img:nth-child(1) {
    top: -175px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(2) {
    top: 184px;
    left: 21%;
}

.hosting-main-banner .hosting-banner-image img:nth-child(3) {
    top: 177px;
    right: 38px;
}

.hosting-main-banner .hosting-banner-image img:nth-child(4) {
    left: 29%;
    right: 0;
    top: -15px;
    margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(5) {
    right: 8px;
    top: -107px;
}

.hosting-main-banner .hosting-banner-image img:nth-child(6) {
    left: 18%;
    top: -175px;
    z-index: -1;
}

.hosting-main-banner .hosting-banner-image img:nth-child(7) {
    top: -235px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(8) {
    left: 27%;
    top: -200px;
}

.hosting-main-banner .hosting-banner-image img:nth-child(9) {
    z-index: -2;
    top: 40px;
    left: 80px;
    right: 0;
    margin: 0 auto;
}

.hosting-main-banner .hosting-banner-image img:nth-child(10) {
    display: none;
}

.hosting-main-banner .hosting-banner-image.single-hosting-banner-image img {
    display: block;
    position: relative;
    top: 0 !important;
    left: 0;
    right: 0;
    bottom: 0;
}

.shape1 {
    position: absolute;
    top: 20%;
    z-index: -1;
    left: 30px;
    -webkit-animation: rotate3d 4s linear infinite;
    animation: rotate3d 4s linear infinite;
    opacity: 0.4;
}

.shape2 {
    position: absolute;
    z-index: -1;
    top: 60%;
    left: 15%;
}

.shape3 {
    position: absolute;
    left: 25%;
    bottom: 15%;
    z-index: -1;
    -webkit-animation: animationFramesOne 15s infinite linear;
    animation: animationFramesOne 15s infinite linear;
}

.shape4 {
    position: absolute;
    right: 25%;
    bottom: 15%;
    z-index: -1;
    -webkit-animation: animationFramesOne 20s infinite linear;
    animation: animationFramesOne 20s infinite linear;
}

.shape5 {
    position: absolute;
    right: 5%;
    top: 10%;
    z-index: -1;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
    opacity: 0.2;
}

.shape6 {
    position: absolute;
    z-index: -1;
    top: 40%;
    right: 10%;
}

.shape7 {
    position: absolute;
    left: 25%;
    top: 15%;
    z-index: -1;
    -webkit-animation: animationFramesOne 20s infinite linear;
    animation: animationFramesOne 20s infinite linear;
}

.shape8 {
    position: absolute;
    z-index: -1;
    top: 15%;
    right: 10%;
}

@-webkit-keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@-webkit-keyframes moveleftbounce {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes moveleftbounce {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@-webkit-keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesOne {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        -webkit-transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        -webkit-transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        -webkit-transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
}

/*================================================
Boxes CSS
=================================================*/

.boxes-area {
    position: relative;
    padding-bottom: 80px;
    margin-top: -130px;
    z-index: 1;
}

.hosting-boxes-area {
    background-color: #032b8d;
    margin-top: 0;
}

.hosting-boxes-area .single-box {
    padding-left: 15px;
    padding-right: 15px;
}

.single-box {
    border: 1px dashed #cdf1d8;
    padding: 30px 20px;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    background: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
    box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box .icon {
    display: inline-block;
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    border-radius: 50%;
    color: #44ce6f;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box h3 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box p {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box.bg-f78acb {
    border-color: rgba(247, 138, 203, 0.3);
}

.single-box.bg-f78acb .icon {
    color: #f78acb;
    background: rgba(247, 138, 203, 0.3);
}

.single-box.bg-c679e3 {
    border-color: #edc3fc;
}

.single-box.bg-c679e3 .icon {
    color: #c679e3;
    background: #edc3fc;
}

.single-box.bg-eb6b3d {
    border-color: rgba(235, 107, 61, 0.3);
}

.single-box.bg-eb6b3d .icon {
    color: #eb6b3d;
    background: rgba(235, 107, 61, 0.3);
}

.single-box:hover {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
    border-color: transparent;
}

.single-box:hover::before {
    opacity: 1;
    visibility: visible;
}

.single-box:hover .icon {
    background: #ffffff;
    color: #44ce6f;
}

.single-box:hover h3, .single-box:hover p {
    color: #ffffff;
}

.single-box:hover.bg-f78acb .icon {
    color: #f78acb;
}

.single-box:hover.bg-c679e3 .icon {
    color: #c679e3;
}

.single-box:hover.bg-eb6b3d .icon {
    color: #eb6b3d;
}

/*==========================================
Pricing CSS
===========================================*/

.pricing-area {
    position: relative;
    z-index: 1;
}

/*================================================
WidgetB CSS
=================================================*/

.fondo-container-widget {
    border-radius: 7px;
    padding: 10px 20px;
}

.label-terminos.terminos label {
    font-size: 1em;
    color: #0e314c !important;
}

.label-terminos.terminos span a {
    color: #981914;
    display: inline;
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
}

.label-terminos.terminos span a:hover {
    color: #b7625f;
}

/*================================================
Calendario CSS
=================================================*/

.about-content.detalles-calendario {
    color: white;
    line-height: 1.9;
}

.about-content.detalles-calendario {
    font-weight: bold;
    letter-spacing: 0.1em;
}

.about-content.detalles-calendario {
    color: white;
    line-height: 1.9;
}

.about-content.detalles-calendario span, .about-content.detalles-calendario ul li::before {
    color: #bc2424;
}

.calendario {
    background: #ececec;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.calendario h5 {
    text-align: center;
}

.calendario .calendario-header {
    font-size: 18px;
    text-transform: uppercase;
    transition: 0.5s;
    margin-bottom: 0;
    color: #bc2424;
    background: #f1f1f1;
    position: relative;
    padding: 20px 20px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #eeeeee;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 1;
}

.calendario .calendario-header::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 0 0;
    /*background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);*/
    background: linear-gradient(135deg, #f3172c 0%, #cda434 100%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: -1;
}

.calendario .calendario-header h3, h5 {
    font-size: 18px;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 0;
}

/* Inicio alerta amarilla */

.calendario .container-reservando-evento {
    font-size: 1rem;
    color: white;
    background-color: #d6b825;
    /* padding: 5px 10px; */
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
}

.calendario .container-reservando-evento p {
    font-weight: 600;
    font-size: 14px;
    color: white;
    padding: 10px 40px;
    text-transform: uppercase;
    transition: 0.5s;
}

.calendario .container-reservando-evento button {
    height: 45px;
    border: 1px;
    border-radius: 5px;
    color: white;
    background-color: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

/* Fin alerta amarilla */

/* Inicio alerta azul */

.calendario .container-evento-reservado {
    font-size: 1rem;
    color: white;
    background-color: #094879;
    /* padding: 5px 10px; */
}

.calendario .container-evento-reservado button {
    height: 45px;
    border: 1px;
    border-radius: 5px;
    color: white;
    background-color: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    cursor: pointer;
}

.calendario .container-evento-reservado button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Fin alerta azul */

/* Inicio alerta celeste input incremental */

.calendario .contenedor-input {
    font-size: 1rem;
    color: white;
    background-color: #0077b5;
    padding: 0px 5px;
}

.calendario .contenedor-input .contenedor-input-incremental {
    font-size: 1rem;
    color: white;
    background-color: transparent;
    padding: 5px 10px;
}

.calendario .contenedor-input .contenedor-input-incremental button {
    border: 1px;
    border-radius: 5px;
    color: white;
    background-color: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.calendario .contenedor-input .contenedor-input-incremental button:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.calendario .contenedor-input .input-counter {
    max-width: 110px;
    min-width: 110px;
    text-align: center;
    display: inline-block;
    position: relative;
}

.calendario .contenedor-input .input-counter span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: wheat;
    width: 40px;
    height: 100%;
    line-height: 47px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.calendario .contenedor-input .input-counter span.minus-btn {
    left: 0;
}

.calendario .contenedor-input .input-counter span.plus-btn {
    right: 0;
}

.calendario .contenedor-input .input-counter span svg {
    width: 20px;
}

.calendario .contenedor-input .input-counter span:hover {
    color: #ffffff;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.15);
}

.calendario .contenedor-input .input-counter input {
    height: 45px;
    color: #d3d3d3;
    outline: 0;
    display: block;
    border: none;
    background-color: transparent;
    text-align: center;
    width: 100%;
    font-size: 17px;
    font-weight: 500;
}

/* Fin alerta celeste input incremental */

.calendario.list {
    padding-bottom: 20px;
}

.calendario .calendario-list {
    height: 700px;
    overflow-y: scroll;
}

.calendario .evento-list {
    text-align: left;
}

.calendario .info-left {
    background: rgba(210, 151, 151, 0.1);
    /* padding-right: 0; */
}

.calendario .info-left img {
    bottom: 0;
}

.calendario .info-right {
    background: rgba(255, 255, 255, 0.2);
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    /* background: #a1a1a1; */
    /* padding-left: 0; */
}

.calendario .info-right label, small {
    color: #4c0e0e;
}

.calendario .card {
    cursor: pointer;
    text-align: left;
}

.calendario .card .fecha {
    color: rgba(255, 255, 255, 0.8);
}

.calendario .form-control {
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    background: #fff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.calendario .form-control:focus {
    outline: 0;
    background: #ffffff;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    border-color: #bc2424;
    /*#44ce6f*/
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}

.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

/*================================================
About CSS
=================================================*/

.about-content .section-title {
    text-align: left;
    margin-bottom: 18px;
}

.about-content .section-title .bar {
    margin-right: 0;
    margin-left: 0;
}

.about-content .section-title .bar.bar-right {
    margin-right: 0;
    margin-left: auto;
}

.about-content .section-title p {
    margin: 0;
}

.about-inner-area {
    margin-top: 60px;
}

.about-inner-area .about-text h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

/*================================================
Why Choose US CSS
=================================================*/

.why-choose-us {
    background: transparent url(../images/img/banner-bg1.jpg) right top no-repeat;
    position: relative;
    z-index: 1;
}

.why-choose-us .section-title {
    text-align: left;
}

.why-choose-us .section-title .bar {
    margin-left: 0;
    margin-right: 0;
}

.why-choose-us .section-title p {
    margin-left: 0;
    margin-right: 0;
}

.why-choose-us-image {
    position: relative;
    text-align: center;
}

.why-choose-us-image .main-pic {
    display: none;
}

.why-choose-us-image img:nth-child(1) {
    position: relative;
    left: -20px;
}

.why-choose-us-image img:nth-child(3) {
    position: absolute;
    top: -55px;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: -1;
}

.why-choose-us-image.single-why-choose-us-image img {
    display: block;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
}

.col-lg-6 .col-lg-6:nth-child(1) .single-why-choose-us {
    margin-top: 60px;
}

.col-lg-6 .col-lg-6:nth-child(2) .single-why-choose-us {
    margin-top: 0;
}

.col-lg-6 .col-lg-6:nth-child(4) .single-why-choose-us {
    margin-top: -30px;
}

.single-why-choose-us {
    padding: 30px 15px;
    border-radius: 5px;
    text-align: center;
    background: #ffffff;
    -webkit-box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
    box-shadow: 0 0 30px rgba(56, 46, 46, 0.1);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-top: 30px;
}

.single-why-choose-us .icon {
    color: #44ce6f;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-why-choose-us .icon i::before {
    font-size: 45px;
}

.single-why-choose-us h3 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-why-choose-us::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-why-choose-us p {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-why-choose-us:hover {
    -webkit-transform: translateY(-9px);
    transform: translateY(-9px);
    border-color: transparent;
}

.single-why-choose-us:hover::before {
    opacity: 1;
    visibility: visible;
}

.single-why-choose-us:hover .icon {
    color: #ffffff;
}

.single-why-choose-us:hover h3, .single-why-choose-us:hover p {
    color: #ffffff;
}

/*================================================
Services CSS
=================================================*/

.services-area {
    overflow: hidden;
}

.services-content .section-title {
    text-align: left;
    margin-bottom: 20px;
}

.services-content .section-title .bar {
    margin-left: 0;
    margin-right: 0;
}

.services-content .section-title p {
    margin: 0;
}

.services-content .row {
    margin-left: -8px;
    margin-right: -8px;
}

.services-content .row .col-lg-6 {
    padding-left: 8px;
    padding-right: 8px;
}

.services-content .box {
    position: relative;
    z-index: 1;
    display: block;
    padding: 22px 12px;
    color: #181818;
    font-size: 16px;
    font-weight: 400;
    border-radius: 3px;
    background-color: #ffffff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    margin-top: 20px;
}

.services-content .box::before {
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, #f3172c 0%, #d48888 100%);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    border-radius: 3px;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.services-content .box svg {
    margin-right: 5px;
    display: inline-block;
    color:
        /*#c03838*/
        #981914;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 40px;
}

.services-content .box:hover {
    color: #ffffff;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.services-content .box:hover::before {
    opacity: 1;
    visibility: visible;
}

.services-content .box:hover svg {
    color: #ffffff;
}

.services-right-image {
    position: relative;
}

.services-right-image img {
    position: absolute;
}

.services-right-image img:nth-child(1) {
    left: 22%;
    top: -85px;
    z-index: 1;
}

.services-right-image img:nth-child(2) {
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    top: -52px;
}

.services-right-image img:nth-child(3) {
    left: 44%;
    top: -33px;
    z-index: 1;
}

.services-right-image img:nth-child(4) {
    right: 38%;
    z-index: 1;
    top: -220px;
}

.services-right-image img:nth-child(5) {
    z-index: 1;
    right: 24%;
    top: 8px;
}

.services-right-image img:nth-child(6) {
    left: 29%;
    top: 0;
    z-index: 1;
}

.services-right-image img:nth-child(7) {
    right: 44%;
    top: -78px;
    z-index: 1;
}

.services-right-image img:nth-child(8) {
    right: 30%;
    top: -134px;
    z-index: 1;
}

.services-right-image img:nth-child(9) {
    right: 31%;
    top: -21px;
    z-index: 1;
}

.services-right-image img:nth-child(10) {
    right: 24%;
    top: -80px;
}

.services-right-image img:nth-child(11) {
    left: 27%;
    top: -60px;
    z-index: 2;
}

.services-right-image img:nth-child(12) {
    right: 28%;
    z-index: 1;
    top: -54px;
}

.services-right-image img:nth-child(13) {
    left: 31%;
    top: -105px;
    z-index: 1;
}

.services-right-image img.bg-image {
    top: -262px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.services-right-image img:last-child {
    display: none;
}

.services-right-image.single-right-image img {
    display: block;
}

.services-right-image.single-right-image img:first-child {
    top: -60px;
}

.services-right-image.single-right-image img:last-child {
    position: relative;
    left: 0;
    top: 0;
}

.services-left-image {
    position: relative;
}

.services-left-image img {
    position: absolute;
}

.services-left-image img:nth-child(1) {
    left: 15%;
    top: -98px;
}

.services-left-image img:nth-child(2) {
    left: 0;
    top: -200px;
    right: 0;
    text-align: center;
    margin: 0 auto;
}

.services-left-image img:nth-child(3) {
    left: 36%;
    z-index: 12;
    top: -55px;
}

.services-left-image img:nth-child(4) {
    left: 55px;
    top: -38px;
    z-index: 1;
}

.services-left-image img:nth-child(5) {
    z-index: 3;
    right: 26%;
    top: -66px;
}

.services-left-image img:nth-child(6) {
    right: 19%;
    top: -41px;
    z-index: 2;
}

.services-left-image img:nth-child(7) {
    right: 97px;
    top: 40px;
    z-index: 1;
}

.services-left-image img:nth-child(8) {
    right: 23%;
    top: -175px;
    z-index: 2;
}

.services-left-image img.bg-image {
    top: -262px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

.services-left-image img:last-child {
    display: none;
}

.services-left-image.single-left-image img {
    display: block;
}

.services-left-image.single-left-image img:first-child {
    top: -60px;
}

.services-left-image.single-left-image img:last-child {
    position: relative;
    left: 0;
    top: 0;
}

.single-services-box {
    position: relative;
    background: #ffffff;
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services-box h3 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.single-services-box .icon {
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #bc24249a;
    border-radius: 50%;
    color: #bc2424;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services-box .icon.bg-c679e3 {
    color: #c679e3;
    background: #edc3fc;
}

.single-services-box .icon.bg-eb6b3d {
    color: #eb6b3d;
    background: rgba(235, 107, 61, 0.3);
}

.single-services-box::before, .single-services-box::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    background: #bc2424;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-services-box::before {
    left: 0;
}

.single-services-box::after {
    right: 0;
}

.single-services-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-services-box:hover .icon {
    background: #44ce6f;
    color: #ffffff;
}

.single-services-box:hover .icon.bg-c679e3 {
    background: #c679e3;
}

.single-services-box:hover .icon.bg-eb6b3d {
    background: #eb6b3d;
}

.single-services-box:hover::after, .single-services-box:hover::before {
    opacity: 1;
    visibility: visible;
    width: 50%;
}

.single-services-box p {
    min-height: 142px;
}

.services-area-two {
    padding-bottom: 50px;
}

/*================================================
Services Details CSS
=================================================*/

.services-details-desc h3 {
    font-size: 20px;
    margin-bottom: 15px;
}

.services-details-desc .services-details-accordion .accordion {
    list-style-type: none;
    padding: 0;
    margin: 25px 0 0;
}

.services-details-desc .services-details-accordion .accordion .accordion-item {
    display: block;
    -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    background: #ffffff;
    margin-bottom: 5px;
}

.services-details-desc .services-details-accordion .accordion .accordion-item:last-child {
    margin-bottom: 0;
}

.services-details-desc .services-details-accordion .accordion .accordion-title {
    display: block;
    padding: 12px 20px 12px 51px;
    text-decoration: none;
    color: #0e314c;
    position: relative;
    border-bottom: 1px solid #ffffff;
    font-size: 15px;
    font-weight: 500;
}

.services-details-desc .services-details-accordion .accordion .accordion-title i {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    text-align: center;
    height: 100%;
    background: #44ce6f;
    color: #ffffff;
}

.services-details-desc .services-details-accordion .accordion .accordion-title i::before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 15px;
}

.services-details-desc .services-details-accordion .accordion .accordion-title.active {
    border-bottom-color: #e1e1e1;
}

.services-details-desc .services-details-accordion .accordion .accordion-title.active i::before {
    content: "\f10f";
}

.services-details-desc .services-details-accordion .accordion .accordion-content {
    display: none;
    position: relative;
    padding: 15px 15px;
}

.services-details-desc .services-details-accordion .accordion .accordion-content.show {
    display: block;
}

.services-details-area .separate {
    margin-top: 80px;
}

.services-details-image {
    text-align: center;
}

/*================================================
Features CSS
=================================================*/

.features-area {
    padding-bottom: 50px;
}

.features-area-two {
    overflow: hidden;
}

.single-features {
    margin: auto;
    position: relative;
    background: #ffffff;
    padding-left: 95px;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-features h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

.single-features .icon {
    position: absolute;
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #f7fafd;
    border-radius: 50%;
    color: #bc2424;
    left: 20px;
    top: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-features .icon.bg-c679e3 {
    color: #c679e3;
    background: #edc3fc;
}

.single-features .icon.bg-eb6b3d {
    color: #eb6b3d;
    background: rgba(235, 107, 61, 0.3);
}

.single-features:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-features:hover .icon {
    background: #bc2424;
    color: #ffffff;
}

.single-features:hover .icon.bg-c679e3 {
    background: #c679e3;
}

.single-features:hover .icon.bg-eb6b3d {
    background: #eb6b3d;
}

.hosting-features-area {
    padding-bottom: 50px;
}

.single-hosting-features {
    position: relative;
    background: #ffffff;
    text-align: center;
    padding-left: 20px;
    padding-top: 30px;
    padding-right: 20px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-hosting-features h3 {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 15px;
}

.single-hosting-features .icon {
    text-align: center;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: #cdf1d8;
    display: inline-block;
    border-radius: 50%;
    color: #44ce6f;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-hosting-features .icon.bg-c679e3 {
    color: #c679e3;
    background: #edc3fc;
}

.single-hosting-features .icon.bg-eb6b3d {
    color: #eb6b3d;
    background: rgba(235, 107, 61, 0.3);
}

.single-hosting-features::before, .single-hosting-features::after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    background: #44ce6f;
    opacity: 0;
    visibility: hidden;
    bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-hosting-features::before {
    left: 0;
}

.single-hosting-features::after {
    right: 0;
}

.single-hosting-features:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-hosting-features:hover .icon {
    background: #44ce6f;
    color: #ffffff;
}

.single-hosting-features:hover .icon.bg-c679e3 {
    background: #c679e3;
}

.single-hosting-features:hover .icon.bg-eb6b3d {
    background: #eb6b3d;
}

.single-hosting-features:hover::after, .single-hosting-features:hover::before {
    opacity: 1;
    visibility: visible;
    width: 50%;
}

/*================================================
Features Details CSS
=================================================*/

.features-details-desc h3 {
    font-size: 20px;
    margin-bottom: 13px;
}

.features-details-desc .features-details-list {
    padding: 0;
    margin: 20px 0 0;
    list-style-type: none;
}

.features-details-desc .features-details-list li {
    margin-bottom: 12px;
    color: #6084a4;
    position: relative;
    padding-left: 15px;
}

.features-details-desc .features-details-list li::before {
    background: #44ce6f;
    width: 8px;
    height: 8px;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    content: "";
}

.features-details-desc .features-details-list li:last-child {
    margin-bottom: 0;
}

.features-details-desc .features-details-accordion .accordion {
    list-style-type: none;
    padding: 0;
    margin: 25px 0 0;
}

.features-details-desc .features-details-accordion .accordion .accordion-item {
    display: block;
    -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    background: #ffffff;
    margin-bottom: 5px;
}

.features-details-desc .features-details-accordion .accordion .accordion-item:last-child {
    margin-bottom: 0;
}

.features-details-desc .features-details-accordion .accordion .accordion-title {
    display: block;
    padding: 12px 20px 12px 51px;
    text-decoration: none;
    color: #0e314c;
    position: relative;
    border-bottom: 1px solid #ffffff;
    font-size: 15px;
    font-weight: 500;
}

.features-details-desc .features-details-accordion .accordion .accordion-title i {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    text-align: center;
    height: 100%;
    background: #44ce6f;
    color: #ffffff;
}

.features-details-desc .features-details-accordion .accordion .accordion-title i::before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 15px;
}

.features-details-desc .features-details-accordion .accordion .accordion-title.active {
    border-bottom-color: #e1e1e1;
}

.features-details-desc .features-details-accordion .accordion .accordion-title.active i::before {
    content: "\f10f";
}

.features-details-desc .features-details-accordion .accordion .accordion-content {
    display: none;
    position: relative;
    padding: 15px 15px;
}

.features-details-desc .features-details-accordion .accordion .accordion-content.show {
    display: block;
}

.features-details-area .separate {
    margin-top: 80px;
}

.features-details-image {
    text-align: center;
}

/*================================================
Team CSS
=================================================*/

.team-area {
    padding-bottom: 50px;
}

.team-area .owl-controls {
    margin-bottom: 30px;
}

.single-team {
    background: #ffffff;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-bottom: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 1;
    text-align: center;
}

.single-team .team-image {
    position: relative;
    z-index: 1;
    padding-left: 35px;
    padding-right: 35px;
}

.single-team .team-image img {
    border: 3px solid #cdf1d8;
    width: auto !important;
    border-radius: 50%;
    display: inline-block !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-team .team-image::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    background: #f9f6f6;
    height: 10px;
    width: 100%;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-team .team-content {
    padding-left: 35px;
    padding-right: 35px;
}

.single-team .team-content ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.single-team .team-content ul li {
    display: inline-block;
    margin: 0 3px;
}

.single-team .team-content ul li a {
    color: #c679e3;
}

.single-team .team-content ul li a:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    color: #44ce6f;
}

.single-team .team-content ul li a svg {
    width: 19px;
}

.single-team .team-content p {
    margin-top: 20px;
    margin-bottom: 0;
}

.single-team .team-info {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    padding: 10px 15px;
    border-radius: 75px;
    margin-bottom: 25px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 25px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-team .team-info h3 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #ffffff;
}

.single-team .team-info span {
    color: #ffffff;
    font-size: 13px;
}

.single-team:hover {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-team:hover .team-image::before {
    height: 0;
}

.single-team:hover .team-image img {
    border-color: #44ce6f;
}

.single-team:hover .team-info {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
}

/*================================================
Works CSS
=================================================*/

.works-area {
    position: relative;
    z-index: 1;
    padding-bottom: 50px;
}

.single-works {
    position: relative;
    margin-bottom: 30px;
}

.single-works::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.single-works .icon {
    position: absolute;
    border-radius: 50%;
    top: 0;
    right: 25px;
    background: #ffffff;
    width: 45px;
    text-align: center;
    height: 45px;
    line-height: 42px;
    opacity: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    visibility: hidden;
}

.single-works .icon svg {
    width: 19px;
}

.single-works .icon:hover {
    background: #0e314c;
    color: #ffffff;
}

.single-works .works-content {
    position: absolute;
    bottom: -60px;
    left: 0;
    padding: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.single-works .works-content h3 {
    font-size: 18px;
    margin-bottom: 15px;
}

.single-works .works-content h3 a {
    color: #ffffff;
}

.single-works .works-content p {
    color: #ffffff;
}

.single-works:hover::before {
    opacity: 0.8;
    visibility: visible;
}

.single-works:hover .icon {
    opacity: 1;
    visibility: visible;
    top: 25px;
}

.single-works:hover .works-content {
    opacity: 1;
    visibility: visible;
    bottom: 0;
}

/*================================================
Fun Facts CSS
=================================================*/

.funfacts-area {
    position: relative;
    z-index: 1;
}

.funfacts-area .section-title h2 {
    max-width: 520px;
    margin: 0 auto;
    line-height: 37px;
}

.funfacts-area .map-bg {
    position: absolute;
    z-index: -1;
    top: 120px;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
}

.funfact {
    text-align: center;
}

.funfact h3 {
    color: #44ce6f;
    font-size: 32px;
    margin-bottom: 7px;
}

.funfact p {
    line-height: initial;
}

.contact-cta-box {
    margin: 80px auto 0;
    max-width: 600px;
    border: 1px dashed #ebebeb;
    padding: 30px 210px 30px 30px;
    border-radius: 5px;
    position: relative;
}

.contact-cta-box h3 {
    font-size: 20px;
    margin-bottom: 6px;
}

.contact-cta-box p {
    margin-bottom: 0;
}

.contact-cta-box .btn {
    position: absolute;
    right: 30px;
    top: 50%;
    margin-top: -27px;
}

.contact-cta-box .btn::before, .contact-cta-box .btn::after {
    background: #ff0000;
}

.contact-cta-box .btn-primary {
    background:
        /*#c03838*/
        #981914;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.contact-cta-box .btn-primary:hover, .contact-cta-box .btn-primary:focus {
    background:
        /*#c03838*/
        #98191400;
    -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
}

/*==========================================
Pricing CSS
===========================================*/

.pricing-area {
    position: relative;
    z-index: 1;
}

.pricing-table {
    text-align: center;
    background: #ffffff;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding-bottom: 35px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.pricing-table .pricing-header {
    background: #ffffff;
    position: relative;
    padding: 30px 20px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #ffffff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 30px;
    z-index: 1;
}

.pricing-table .pricing-header::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px 5px 0 0;
    /*background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);*/
    background: linear-gradient(135deg, #b91817 0%, #690000e3 100%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: -1;
}

.pricing-table .pricing-header h3 {
    font-size: 18px;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 0;
}

.pricing-table .price {
    margin-bottom: 30px;
}

.pricing-table .price span {
    color: #bc2424;
    font-size: 30px;
    font-weight: 600;
}

.pricing-table .price span sup {
    font-weight: 500;
}

.pricing-table .price span span {
    font-size: 14px;
    font-weight: 500;
}

.pricing-table .pricing-features ul {
    padding: 0;
    list-style-type: none;
    margin-bottom: 30px;
}

.pricing-table .pricing-features ul li {
    color: #ccdcea;
    margin-bottom: 12px;
    text-decoration: line-through;
}

.pricing-table .pricing-features ul li:last-child {
    margin-bottom: 0;
}

.pricing-table .pricing-features ul li.active {
    color: #6084a4;
    text-decoration: none;
}

.pricing-table:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.pricing-table:hover .pricing-header::before {
    opacity: 1;
    visibility: visible;
}

.pricing-table:hover .pricing-header h3 {
    color: #ffffff;
}

.pricing-table.active-plan .pricing-header::before {
    opacity: 1;
    visibility: visible;
}

.pricing-table.active-plan .pricing-header h3 {
    color: #ffffff;
}

.pricing-table.active-plan .btn-primary {
    background: #c679e3;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.pricing-table.active-plan .btn-primary::after, .pricing-table.active-plan .btn-primary::before {
    background: #44ce6f;
    -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
}

.single-pricing-table {
    text-align: center;
    background: #ffffff;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 35px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-pricing-table .pricing-header i {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-pricing-table .pricing-header i::before {
    font-size: 45px;
}

.single-pricing-table .pricing-header h3 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin: 15px 0 20px;
}

.single-pricing-table .price {
    margin-bottom: 20px;
}

.single-pricing-table .price span {
    color: #44ce6f;
    font-size: 30px;
    font-weight: 600;
}

.single-pricing-table .price span sup {
    font-weight: 500;
}

.single-pricing-table .price span span {
    font-size: 14px;
    font-weight: 500;
}

.single-pricing-table .pricing-features ul {
    padding: 0;
    margin: 0 0 30px;
    list-style-type: none;
}

.single-pricing-table .pricing-features ul li {
    color: #6084a4;
    margin-bottom: 12px;
}

.single-pricing-table .pricing-features ul li:last-child {
    margin-bottom: 0;
}

.single-pricing-table .pricing-features ul li svg {
    width: 15px;
}

.single-pricing-table.active-plan .btn-primary {
    background: #c679e3;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.single-pricing-table.active-plan .btn-primary::after, .single-pricing-table.active-plan .btn-primary::before {
    background: #44ce6f;
    -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
}

.tab .tabs_item {
    display: none;
}

.tab .tabs_item:first-child {
    display: block;
}

.tab .tabs_item:nth-child(2) .pricing-box {
    background: -webkit-gradient(linear, left bottom, right top, from(#fffcff), color-stop(#fbfaff), color-stop(#effbff), color-stop(#e0fdff), to(#d5fefd));
    background: linear-gradient(to right top, #fffcff, #fbfaff, #effbff, #e0fdff, #d5fefd);
}

.pricing-tab .tabs {
    padding-left: 0;
    margin-bottom: 45px;
    list-style-type: none;
    text-align: center;
}

.pricing-tab .tabs li {
    display: inline-block;
}

.pricing-tab .tabs li a {
    background: -webkit-gradient(linear, right top, left bottom, from(#fffcff), color-stop(#fbfaff), color-stop(#effbff), color-stop(#e0fdff), to(#d5fefd));
    background: linear-gradient(to left bottom, #fffcff, #fbfaff, #effbff, #e0fdff, #d5fefd);
    padding: 10px 30px 9.5px;
    margin-left: -2px;
    margin-right: -2px;
    font-weight: 500;
    font-size: 15px;
}

.pricing-tab .tabs li a:hover {
    color: #0e314c;
    background: -webkit-gradient(linear, right top, left bottom, from(#fffcff), color-stop(#fbfaff), color-stop(#effbff), color-stop(#e0fdff), to(#d5fefd));
    background: linear-gradient(to left bottom, #fffcff, #fbfaff, #effbff, #e0fdff, #d5fefd);
}

.pricing-tab .tabs li.current a {
    color: #ffffff;
    background: #0e314c;
}

.pricing-tab .tabs li:nth-child(1) a {
    border-radius: 5px 0 0 5px;
}

.pricing-tab .tabs li:nth-child(1) a::before {
    border-radius: 5px 0 0 5px;
}

.pricing-tab .tabs li:nth-child(2) a {
    border-radius: 0 5px 5px 0;
}

.pricing-tab .tabs li:nth-child(2) a::before {
    border-radius: 0 5px 5px 0;
}

.pricing-box {
    background: -webkit-gradient(linear, right top, left bottom, from(#fffcff), color-stop(#fbfaff), color-stop(#effbff), color-stop(#e0fdff), to(#d5fefd));
    background: linear-gradient(to left bottom, #fffcff, #fbfaff, #effbff, #e0fdff, #d5fefd);
    text-align: center;
    padding: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
}

.pricing-box .pricing-header h3 {
    margin-bottom: 0;
    font-size: 20px;
}

.pricing-box .pricing-header p {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    line-height: 1.6;
    margin-top: 10px;
    margin-bottom: 0;
}

.pricing-box .price {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 20px;
    color: #ff0000;
}

.pricing-box .price span {
    display: inline-block;
    margin-left: -7px;
    font-size: 20px;
    font-weight: 600;
    color: #ff0000;
}

.pricing-box .buy-btn {
    margin-bottom: 30px;
}

.pricing-box .buy-btn .btn {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    -webkit-transform: unset !important;
    transform: unset !important;
}

.pricing-box .buy-btn .btn-primary {
    border-radius: 30px;
    background: -webkit-gradient(linear, left bottom, right top, from(#a3a3a3), color-stop(#9a9a9a), color-stop(#909090), color-stop(#878787), to(#7e7e7e));
    background: linear-gradient(to right top, #a3a3a3, #9a9a9a, #909090, #878787, #7e7e7e);
    text-transform: capitalize;
    padding: 11px 30px;
    font-weight: 500;
}

.pricing-box .buy-btn .btn-primary::after {
    border-radius: 30px;
    background: #44ce6f;
    width: 100%;
    opacity: 1;
    visibility: visible;
}

.pricing-box .buy-btn .btn-primary::before {
    display: none;
}

.pricing-box .buy-btn .btn-primary:not(:disabled):not(.disabled).active, .pricing-box .buy-btn .btn-primary:not(:disabled):not(.disabled):active, .pricing-box .buy-btn .btn-primary .show>.pricing-box .buy-btn .btn-primary.dropdown-toggle {
    background: -webkit-gradient(linear, left bottom, right top, from(#a3a3a3), color-stop(#9a9a9a), color-stop(#909090), color-stop(#878787), to(#7e7e7e));
    background: linear-gradient(to right top, #a3a3a3, #9a9a9a, #909090, #878787, #7e7e7e);
}

.pricing-box .buy-btn .btn-primary:hover::after, .pricing-box .buy-btn .btn-primary:focus::after {
    opacity: 0;
    visibility: hidden;
}

.pricing-box .pricing-features {
    margin-bottom: 0;
    list-style-type: none;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
}

.pricing-box .pricing-features li {
    margin-bottom: 12px;
    color: #495771;
    position: relative;
    padding-left: 27px;
}

.pricing-box .pricing-features li:last-child {
    margin-bottom: 0;
}

.pricing-box .pricing-features li svg {
    color: #44ce6f;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 20px;
}

/*================================================
Domain Search CSS
=================================================*/

.domain-search-content {
    text-align: center;
    max-width: 750px;
    margin: 0 auto;
}

.domain-search-content h2 {
    text-transform: capitalize;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
}

.domain-search-content form {
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    overflow: hidden;
    border-radius: 30px;
}

.domain-search-content form .form-control {
    background-color: #ffffff;
    border: none;
    height: 55px;
    padding-left: 20px;
    float: left;
    width: 63%;
}

.domain-search-content form .domain-select {
    float: left;
    width: 15%;
    padding-right: 20px;
}

.domain-search-content form .domain-select .form-control {
    padding: 0;
    float: unset;
    width: 100%;
    cursor: pointer;
    height: 55px;
}

.domain-search-content form button {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    float: left;
    width: 22%;
    height: 55px;
    border-radius: 30px;
    background-color: #44ce6f;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    outline: 0;
    cursor: pointer;
    border: none;
    padding: 15px 40px;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.domain-search-content form button:hover, .domain-search-content form button:focus {
    background-color: #c679e3;
}

.domain-search-content ul {
    padding: 0;
    margin: 30px 0 0;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.domain-search-content ul li {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-weight: 500;
    border-left: 1px solid #eeeeee;
}

.domain-search-content ul li:first-child {
    border-left: none;
}

/*================================================
Feedback CSS
=================================================*/

.feedback-area {
    position: relative;
    z-index: 1;
}

.feedback-slides {
    position: relative;
    max-width: 750px;
    margin: 0 auto;
}

.feedback-slides .client-feedback {
    position: relative;
}

.feedback-slides .client-feedback .single-feedback {
    background: #ffffff;
    border-radius: 5px;
    padding-left: 170px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 60px;
    position: relative;
}

.feedback-slides .client-feedback .single-feedback .client-img {
    position: absolute;
    left: 40px;
    top: 40px;
}

.feedback-slides .client-feedback .single-feedback .client-img img {
    border-radius: 50%;
    border: 2px solid
        /*#c03838*/
        #981914;
    padding: 3px;
    width: 95px !important;
    display: inline-block !important;
}

.feedback-slides .client-feedback .single-feedback h3 {
    font-size: 18px;
    margin: 0;
}

.feedback-slides .client-feedback .single-feedback span {
    display: block;
    margin-top: 8px;
    margin-bottom: 18px;
    color:
        /*#c03838*/
        #981914;
}

.feedback-slides .client-feedback .single-feedback p {
    font-size: 15px;
    color: rgb(32, 32, 32);
}

.feedback-slides .client-feedback .single-feedback::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 50px;
    height: 50px;
    background: #ffffff;
    right: 0;
    margin: 0 auto;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.feedback-slides .client-thumbnails {
    position: relative;
    margin: 0 85px;
}

.feedback-slides .client-thumbnails .item .img-fill {
    cursor: pointer;
    position: relative;
    text-align: center;
}

.feedback-slides .client-thumbnails .item .img-fill img {
    opacity: 0.4;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    display: inline-block;
    position: relative;
    border: 2px solid
        /*#c03838*/
        #981914;
    border-radius: 50%;
    padding: 3px;
    width: 85px;
}

.feedback-slides .client-thumbnails .item:hover .img-fill img, .feedback-slides .client-thumbnails .item.slick-center .img-fill img {
    opacity: 1;
}

.client-thumbnails .next-arrow, .client-thumbnails .prev-arrow {
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: transparent;
    color: #981914;
    border: 2px solid #981914;
    z-index: 1;
    border-radius: 50%;
    line-height: 30px;
    outline: 0 !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.client-thumbnails .next-arrow::before, .client-thumbnails .prev-arrow::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #981914;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
}

.client-thumbnails .next-arrow:hover, .client-thumbnails .prev-arrow:hover {
    color: #ffffff;
    border-color:
        /*#c03838*/
        #981914;
}

.client-thumbnails .next-arrow:hover::before, .client-thumbnails .prev-arrow:hover::before {
    opacity: 1;
    visibility: visible;
}

.client-thumbnails .next-arrow svg, .client-thumbnails .prev-arrow svg {
    width: 18px;
    height: 18px;
    position: relative;
}

.client-thumbnails .next-arrow {
    right: -20px;
}

.client-thumbnails .prev-arrow {
    left: -20px;
}

.testimonials-slides {
    background-color: #f7fbfe;
    max-width: 850px;
    margin: 90px auto 30px;
    position: relative;
    z-index: 1;
}

.testimonials-slides::before {
    position: absolute;
    left: -30px;
    top: -30px;
    width: 260px;
    height: 260px;
    content: "";
    z-index: -1;
    background-image: url(../images/img/layer.png);
}

.testimonials-slides::after {
    position: absolute;
    right: -30px;
    bottom: -30px;
    width: 260px;
    height: 260px;
    content: "";
    z-index: -1;
    background-image: url(../images/img/layer.png);
}

.testimonials-slides .owl-controls {
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot span {
    width: 18px;
    height: 18px;
    background: transparent;
    border: 2px solid #d6d6d6;
    position: relative;
    margin: 0 3px;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot span::before {
    background: #d6d6d6;
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin: 0 auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot:hover span, .testimonials-slides.owl-theme .owl-dots .owl-dot.active span {
    border-color: #44ce6f;
}

.testimonials-slides.owl-theme .owl-dots .owl-dot:hover span::before, .testimonials-slides.owl-theme .owl-dots .owl-dot.active span::before {
    background: #44ce6f;
}

.single-feedback-item {
    padding: 40px 130px 90px;
    text-align: center;
    background-color: #f7fbfe;
    position: relative;
}

.single-feedback-item .client-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: left;
    max-width: 280px;
    margin: 0 auto 25px;
}

.single-feedback-item .client-info .image {
    padding-right: 20px;
}

.single-feedback-item .client-info .image img {
    display: inline-block !important;
    width: 75px;
    border-radius: 50%;
    border: 3px solid #44ce6f;
}

.single-feedback-item .client-info .title h3 {
    font-size: 18px;
    margin: 0;
}

.single-feedback-item .client-info .title span {
    display: block;
    margin-top: 8px;
    color: #c679e3;
}

.single-feedback-item p {
    font-size: 16px;
    font-style: italic;
}

.single-feedback-item::before {
    content: "\f11b";
    color: #d9dcde;
    font-family: Flaticon;
    font-size: 30px;
    font-style: normal;
    font-size: 65px;
    position: absolute;
    top: 0;
    left: 25px;
}

.agency-portfolio-feedback-slides {
    max-width: 730px;
    margin-left: auto;
    margin-right: auto;
}

.agency-portfolio-feedback-item {
    margin-bottom: 20px;
    text-align: center;
}

.agency-portfolio-feedback-item img {
    width: auto !important;
    display: inline-block !important;
    border-radius: 50%;
    border: 3px solid #999999;
}

.agency-portfolio-feedback-item p {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
}

/*================================================
Ready To Talk CSS
=================================================*/

.ready-to-talk {
    text-align: center;
    position: relative;
    padding-top: 80px;
    padding-bottom: 160px;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
}

.ready-to-talk h3 {
    font-size: 28px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.ready-to-talk p {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 16px;
}

.ready-to-talk .btn::before, .ready-to-talk .btn::after {
    background: #ffffff;
}

.ready-to-talk .btn-primary {
    background: #c679e3;
    -webkit-box-shadow: 0 5px 20px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 5px 20px 0 rgba(198, 121, 227, 0.25);
}

.ready-to-talk .btn-primary:hover, .ready-to-talk .btn-primary:focus {
    color: #0e314c;
    -webkit-box-shadow: 0 5px 20px 0 rgba(68, 206, 111, 0.25);
    box-shadow: 0 5px 20px 0 rgba(68, 206, 111, 0.25);
}

.ready-to-talk span a {
    margin-left: 15px;
    color: #ffffff;
    text-decoration: underline;
}

/*================================================
Partner CSS
=================================================*/

.partner-section {
    text-align: center;
    position: relative;
    margin-top: -120px;
    background: transparent;
}

.partner-section h5 {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 0;
}

.partner-section .partner-inner {
    background: #ffffff;
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 3px;
    margin-top: 25px;
}

.partner-section .partner-inner a {
    display: block;
    margin-top: 25px;
    position: relative;
}

.partner-section .partner-inner a img:last-child {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.partner-section .partner-inner a:hover img:last-child {
    opacity: 1;
    top: 0;
    visibility: visible;
}

/*================================================
Blog CSS
=================================================*/

.blog-area {
    position: relative;
    z-index: 1;
    padding-bottom: 50px;
}

.blog-area .pagination-area {
    margin-bottom: 30px;
}

.single-blog-post {
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 30px;
}

.single-blog-post .blog-image {
    position: relative;
}

.single-blog-post .blog-image a {
    display: block;
}

.single-blog-post .blog-image .date {
    position: absolute;
    left: 20px;
    bottom: -20px;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    color: #ffffff;
    padding: 8px 16px;
    font-size: 13px;
    border-radius: 50px;
}

.single-blog-post .blog-image .date svg {
    width: 15px;
    margin-right: 3px;
}

.single-blog-post .blog-post-content {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    background: #f7fafd;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-blog-post .blog-post-content h3 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
}

.single-blog-post .blog-post-content span {
    display: block;
    color: #0e314c;
    margin: 15px 0;
}

.single-blog-post .blog-post-content span a {
    color: #6084a4;
}

.single-blog-post .blog-post-content span a:hover {
    color: #44ce6f;
}

.single-blog-post .blog-post-content .read-more-btn {
    color: #0e314c;
}

.single-blog-post .blog-post-content .read-more-btn svg {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 15px;
}

.single-blog-post .blog-post-content .read-more-btn:hover {
    color: #44ce6f;
}

.single-blog-post .blog-post-content .read-more-btn:hover svg {
    margin-left: 5px;
}

.single-blog-post:hover {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.single-blog-post:hover .blog-post-content {
    background: #ffffff;
}

.single-blog-post-box {
    margin-bottom: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
}

.single-blog-post-box .entry-thumbnail a img {
    border-radius: 5px 5px 0 0;
}

.single-blog-post-box .entry-post-content {
    background: -webkit-gradient(linear, left bottom, right top, from(#d5fefd), color-stop(#e0fdff), color-stop(#effbff), color-stop(#fbfaff), to(#fffcff));
    background: linear-gradient(to right top, #d5fefd, #e0fdff, #effbff, #fbfaff, #fffcff);
    padding: 20px;
}

.single-blog-post-box .entry-post-content .entry-meta {
    margin-bottom: 10px;
}

.single-blog-post-box .entry-post-content .entry-meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.single-blog-post-box .entry-post-content .entry-meta ul li {
    display: inline-block;
    position: relative;
    margin-right: 12px;
    color: #6084a4;
    font-size: 14px;
}

.single-blog-post-box .entry-post-content .entry-meta ul li::before {
    content: "";
    position: absolute;
    right: -7px;
    top: 50%;
    -webkit-transform: rotate(14deg) translateY(-50%);
    transform: rotate(14deg) translateY(-50%);
    background: #6084a4;
    width: 1px;
    height: 13px;
}

.single-blog-post-box .entry-post-content .entry-meta ul li a {
    display: inline-block;
    color: #6084a4;
}

.single-blog-post-box .entry-post-content .entry-meta ul li a:hover {
    color: #44ce6f;
}

.single-blog-post-box .entry-post-content .entry-meta ul li:last-child {
    margin-right: 0;
}

.single-blog-post-box .entry-post-content .entry-meta ul li:last-child::before {
    display: none;
}

.single-blog-post-box .entry-post-content h3 {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 18px;
}

.single-blog-post-box .entry-post-content p {
    margin-top: 8px;
    margin-bottom: 0;
}

.single-blog-post-box .entry-post-content .learn-more-btn {
    margin-top: 15px;
    font-weight: 600;
}

.single-blog-post-box .entry-post-content .learn-more-btn svg {
    width: 15px;
}

.single-blog-item {
    margin-bottom: 30px;
}

.single-blog-item .post-image {
    text-align: center;
}

.single-blog-item .post-image a {
    display: block;
}

.single-blog-item .post-content {
    margin-top: 20px;
}

.single-blog-item .post-content .post-meta {
    padding-left: 0;
    margin-bottom: 10px;
    list-style-type: none;
}

.single-blog-item .post-content .post-meta li {
    display: inline-block;
    margin-right: 25px;
    position: relative;
}

.single-blog-item .post-content .post-meta li::before {
    content: "";
    position: absolute;
    top: 10px;
    right: -20px;
    width: 10px;
    height: 1px;
    background-color: #6084a4;
}

.single-blog-item .post-content .post-meta li:last-child {
    margin-right: 0;
}

.single-blog-item .post-content .post-meta li:last-child::before {
    display: none;
}

.single-blog-item .post-content h3 {
    line-height: 30px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
}

.blog-slides {
    margin-bottom: 30px;
}

.single-blog-post-item {
    margin-bottom: 30px;
}

.single-blog-post-item .post-image {
    border-radius: 5px;
    overflow: hidden;
}

.single-blog-post-item .post-image a {
    display: block;
    border-radius: 5px;
}

.single-blog-post-item .post-image a img {
    border-radius: 5px;
}

.single-blog-post-item .post-content {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: -webkit-gradient(linear, left bottom, right top, from(#d5fefd), color-stop(#e0fdff), color-stop(#effbff), color-stop(#fbfaff), to(#fffcff));
    background: linear-gradient(to right top, #d5fefd, #e0fdff, #effbff, #fbfaff, #fffcff);
    position: relative;
    border-radius: 5px;
    padding: 30px 25px;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
}

.single-blog-post-item .post-content .post-meta {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 10px;
    list-style-type: none;
    padding-left: 0;
}

.single-blog-post-item .post-content .post-meta li {
    display: inline-block;
    margin-right: 15px;
    position: relative;
    color: #6084a4;
}

.single-blog-post-item .post-content .post-meta li a {
    color: #6084a4;
}

.single-blog-post-item .post-content .post-meta li a:hover {
    color: #44ce6f;
}

.single-blog-post-item .post-content .post-meta li::before {
    content: "";
    position: absolute;
    right: -10px;
    top: 4px;
    height: 12px;
    background-color: #6084a4;
    width: 1px;
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
}

.single-blog-post-item .post-content .post-meta li:last-child {
    margin-right: 0;
}

.single-blog-post-item .post-content .post-meta li:last-child::before {
    display: none;
}

.single-blog-post-item .post-content h3 {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
}

.single-blog-post-item .post-content .read-more-btn {
    display: inline-block;
    border-radius: 30px;
    padding: 9px 25px;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    left: 25px;
    bottom: -22px;
    opacity: 0;
    visibility: hidden;
    font-size: 14px;
    font-weight: 500;
}

.single-blog-post-item .post-content .read-more-btn svg {
    width: 15px;
    margin-left: 2px;
}

.single-blog-post-item .post-content .read-more-btn:hover {
    background-color: #44ce6f;
    border-color: #44ce6f;
    color: #ffffff;
}

.single-blog-post-item:hover .post-content {
    -webkit-transform: translateY(-22px);
    transform: translateY(-22px);
}

.single-blog-post-item:hover .post-content .post-meta {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
}

.single-blog-post-item:hover .post-content h3 {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
}

.single-blog-post-item:hover .post-content .read-more-btn {
    opacity: 1;
    visibility: visible;
}

/*================================================
Shop CSS
=================================================*/

.woocommerce-topbar {
    background-color: #fcfbfb;
    padding: 10px 40px;
    margin-bottom: 30px;
}

.woocommerce-topbar .woocommerce-topbar-ordering .form-control {
    cursor: pointer;
    background-color: transparent;
    border-color: #ebebeb;
    border-radius: 4px;
}

.single-products {
    text-align: center;
    position: relative;
    margin-bottom: 30px;
}

.single-products .products-content {
    margin-top: 20px;
}

.single-products .products-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.single-products .products-content ul {
    padding: 0;
    margin: 10px 0 12px;
    list-style-type: none;
}

.single-products .products-content ul li {
    display: inline-block;
    color: #eac11d;
}

.single-products .products-content ul li i::before {
    font-size: 15px;
}

.single-products .products-content .add-to-cart-btn {
    display: block;
    width: 100%;
    color: #0e314c;
    border: 1px solid #0e314c;
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: 500;
}

.single-products .sale-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #ff2d2d;
    color: #fff;
    width: 55px;
    height: 55px;
    line-height: 55px;
    border-radius: 50%;
    z-index: 2;
}

.single-products .products-image {
    overflow: hidden;
    position: relative;
}

.single-products .products-image img {
    -webkit-transition: all 1.5s ease-out;
    transition: all 1.5s ease-out;
    width: 100%;
}

.single-products .products-image::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: gray;
    content: "";
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
}

.single-products .products-image ul {
    padding: 0;
    z-index: 2;
    list-style-type: none;
    margin: 0;
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-products .products-image ul li {
    display: inline-block;
}

.single-products .products-image ul li a {
    display: block;
    background: #ffffff;
    width: 35px;
    height: 35px;
    line-height: 35px;
}

.single-products .products-image ul li a svg {
    width: 20px;
}

.single-products .products-image ul li a:hover, .single-products .products-image ul li a:focus {
    background-color: #44ce6f;
    color: #ffffff;
}

.single-products .products-image ul li a:nth-child(1) {
    margin-left: -15px;
}

.single-products .products-image ul li a:nth-child(3) {
    margin-right: -15px;
}

.single-products:hover .products-content .add-to-cart-btn, .single-products:focus .products-content .add-to-cart-btn {
    background-color: #0e314c;
    color: #ffffff;
}

.single-products:hover .products-image img, .single-products:focus .products-image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.single-products:hover .products-image::before, .single-products:focus .products-image::before {
    opacity: 0.4;
    visibility: visible;
}

.single-products:hover .products-image ul, .single-products:focus .products-image ul {
    opacity: 1;
    visibility: visible;
}

.single-products:hover .products-image ul li a:nth-child(1), .single-products:focus .products-image ul li a:nth-child(1) {
    margin-left: 0;
}

.single-products:hover .products-image ul li a:nth-child(3), .single-products:focus .products-image ul li a:nth-child(3) {
    margin-right: 0;
}

#productsModalCenter .modal-dialog {
    max-width: 900px;
}

#productsModalCenter .modal-content {
    border: none;
    border-radius: 0;
}

#productsModalCenter .modal-content button.close {
    position: absolute;
    right: 2%;
    display: inline-block;
    opacity: 1;
    top: 2%;
    width: 35px;
    height: 35px;
    text-align: center;
    line-height: 32px;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

#productsModalCenter .modal-content button.close span {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

#productsModalCenter .modal-content button.close:hover, #productsModalCenter .modal-content button.close:focus {
    background-color: #44ce6f;
    color: #ffffff;
}

#productsModalCenter .modal-content button.close:hover span, #productsModalCenter .modal-content button.close:focus span {
    color: #ffffff;
}

#productsModalCenter .modal-content .products-image {
    overflow: hidden;
}

#productsModalCenter .modal-content .products-image img {
    width: 100%;
}

#productsModalCenter .modal-content .products-content p {
    margin-bottom: 20px;
}

#productsModalCenter .modal-content .products-content h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

#productsModalCenter .modal-content .products-content .price {
    margin-bottom: 15px;
}

#productsModalCenter .modal-content .products-content .price .tachado {
    text-decoration: line-through;
    color: #6084a4;
}

#productsModalCenter .products-content form .quantity {
    display: inline-block;
    margin-right: 8px;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter {
    max-width: 110px;
    min-width: 110px;
    text-align: center;
    display: inline-block;
    position: relative;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: #6084a4;
    width: 40px;
    height: 100%;
    line-height: 47px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 0;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter span.minus-btn {
    left: 0;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter span.plus-btn {
    right: 0;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter span svg {
    width: 20px;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter span:hover {
    color: #44ce6f;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter input {
    height: 47px;
    color: #0e314c;
    outline: 0;
    display: block;
    border: none;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
    font-size: 17px;
    font-weight: 500;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter input::-webkit-input-placeholder {
    color: #0e314c;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter input:-ms-input-placeholder {
    color: #0e314c;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter input::-ms-input-placeholder {
    color: #0e314c;
}

#productsModalCenter .modal-content .products-content form .quantity .input-counter input::placeholder {
    color: #0e314c;
}

#productsModalCenter .modal-content .products-content form button {
    background: #44ce6f;
    color: #ffffff;
    border: none;
    height: 46px;
    padding: 0 20px;
    position: relative;
    top: -2px;
    cursor: pointer;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

#productsModalCenter .modal-content .products-content form button:hover, #productsModalCenter .modal-content .products-content form button:focus {
    background-color: #c679e3;
    color: #ffffff;
}

#productsModalCenter .modal-content .products-content .product-meta {
    margin-top: 15px;
}

#productsModalCenter .modal-content .products-content .product-meta span {
    display: inline-block;
    margin-right: 8px;
}

/*================================================
Shop Details CSS
=================================================*/

.products-details-image img {
    width: 100%;
}

.products-details-image .slick-slider {
    position: relative;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.products-details-image .slick-dots {
    padding: 0;
    text-align: center;
    margin: 10px 0 0;
    list-style-type: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.products-details-image .slick-dots li {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 5px;
}

.products-details-image .slick-dots li button {
    padding: 0;
    outline: 0;
    border: none;
    margin: 0;
    cursor: pointer;
}

.products-details-image .slick-dots li:last-child {
    margin-right: 0;
}

.products-details-image .slick-dots li:first-child {
    margin-left: 0;
}

.products-details-image .slick-thumbs {
    display: none;
}

.products-details h3 {
    font-size: 20px;
    margin-bottom: 8px;
}

.products-details .price {
    margin-bottom: 15px;
}

.products-details .price .tachado {
    text-decoration: line-through;
    color: #6084a4;
}

.products-details .rating {
    padding: 0;
    margin: 0 0 12px;
    list-style-type: none;
}

.products-details .rating li {
    display: inline-block;
    color: #eac11d;
}

.products-details .rating li i::before {
    font-size: 15px;
}

.products-details .availability {
    margin-bottom: 20px;
}

.products-details .availability span {
    color: #44ce6f;
}

.products-details form .quantity {
    display: inline-block;
    padding-right: 10px;
}

.products-details form .quantity span {
    padding-right: 10px;
}

.products-details form .quantity .input-counter {
    max-width: 110px;
    min-width: 110px;
    text-align: center;
    display: inline-block;
    position: relative;
}

.products-details form .quantity .input-counter span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: #6084a4;
    width: 40px;
    height: 100%;
    line-height: 47px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 0;
}

.products-details form .quantity .input-counter span.minus-btn {
    left: 0;
}

.products-details form .quantity .input-counter span.plus-btn {
    right: 0;
}

.products-details form .quantity .input-counter span svg {
    width: 20px;
}

.products-details form .quantity .input-counter span:hover {
    color: #44ce6f;
}

.products-details form .quantity .input-counter input {
    height: 47px;
    color: #0e314c;
    outline: 0;
    display: block;
    border: none;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
    font-size: 17px;
    font-weight: 500;
}

.products-details form .quantity .input-counter input::-webkit-input-placeholder {
    color: #0e314c;
}

.products-details form .quantity .input-counter input:-ms-input-placeholder {
    color: #0e314c;
}

.products-details form .quantity .input-counter input::-ms-input-placeholder {
    color: #0e314c;
}

.products-details form .quantity .input-counter input::placeholder {
    color: #0e314c;
}

.products-details form button {
    background: #44ce6f;
    color: #ffffff;
    border: none;
    height: 46px;
    padding: 0 20px;
    position: relative;
    top: -3px;
    cursor: pointer;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.products-details form button:hover, .products-details form button:focus {
    background-color: #c679e3;
    color: #ffffff;
}

.products-details form .add-to-wishlist-btn {
    margin-left: 5px;
    background: transparent;
    color: #0e314c;
    border: 1px solid #eeeeee;
    position: relative;
    top: -3px;
    cursor: pointer;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
}

.products-details form .add-to-wishlist-btn:hover, .products-details form .add-to-wishlist-btn:focus {
    background-color: #c679e3;
    color: #ffffff;
    border-color: #c679e3;
}

.products-details form .buy-btn {
    margin-top: 20px;
}

.products-details form .buy-btn .btn-primary {
    background: #c679e3;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.products-details form .buy-btn .btn-primary::after, .products-details form .buy-btn .btn-primary::before {
    background: #44ce6f;
    -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
}

.products-details .custom-payment-options {
    margin-top: 20px;
}

.products-details .custom-payment-options span {
    display: block;
    margin-bottom: 10px;
}

.products-details .products-share-social {
    margin-top: 25px;
}

.products-details .products-share-social span {
    display: inline-block;
    margin-right: 10px;
}

.products-details .products-share-social ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: inline-block;
}

.products-details .products-share-social ul li {
    display: inline-block;
}

.products-details .products-share-social ul li a {
    width: 32px;
    text-align: center;
    line-height: 27px;
    height: 32px;
    border: 1px solid #44ce6f;
    border-radius: 50%;
    color: #44ce6f;
}

.products-details .products-share-social ul li a svg {
    width: 15px;
}

.products-details .products-share-social ul li a.facebook {
    border-color: #4267b2;
    color: #4267b2;
}

.products-details .products-share-social ul li a.facebook:hover {
    background: #4267b2;
    color: #ffffff;
}

.products-details .products-share-social ul li a.twitter {
    border-color: #38a1f3;
    color: #38a1f3;
}

.products-details .products-share-social ul li a.twitter:hover {
    background: #38a1f3;
    color: #ffffff;
}

.products-details .products-share-social ul li a.linkedin {
    border-color: #0077b5;
    color: #0077b5;
}

.products-details .products-share-social ul li a.linkedin:hover {
    background: #0077b5;
    color: #ffffff;
}

.products-details .products-share-social ul li a.instagram {
    border-color: #231f20;
    color: #231f20;
}

.products-details .products-share-social ul li a.instagram:hover {
    background: #231f20;
    color: #ffffff;
}

.products-details .products-share-social ul li a:hover {
    background: #44ce6f;
    color: #ffffff;
}

.products-details-tabs {
    margin-top: 40px;
    text-align: center;
}

.products-details-tabs #tabs {
    padding: 0;
    margin: 0 0 30px;
    list-style-type: none;
}

.products-details-tabs #tabs li {
    display: inline-block;
    padding: 12px 50px 8px;
    cursor: pointer;
    margin-right: -4px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: #eaeaea;
    font-weight: 500;
}

.products-details-tabs #tabs li.active {
    background-color: #ffffff;
    position: relative;
}

.products-details-tabs #tabs li.active::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    top: 0;
    left: 0;
    background: #44ce6f;
}

.products-details-tabs #tabs li.inactive {
    background-color: #eeeeee;
    padding: 12px 50px;
}

.products-details-tabs .content {
    display: none;
}

.products-details-tabs .content.show {
    display: block;
}

.products-details-tabs .content h3 {
    margin-bottom: 12px;
    font-size: 18px;
}

.products-details-tabs .content .additional-information {
    padding: 0;
    margin: 25px 0 0;
    list-style-type: none;
    text-align: left;
}

.products-details-tabs .content .additional-information li {
    border: 1px solid #eeeeee;
    border-bottom: none;
    padding: 10px 15px;
    color: #6084a4;
}

.products-details-tabs .content .additional-information li:last-child {
    border-bottom: 1px solid #eeeeee;
}

.products-details-tabs .content .additional-information li span {
    display: inline-block;
    width: 45%;
}

.products-details-tabs .content .products-reviews {
    text-align: left;
    max-width: 700px;
    margin: 0 auto;
}

.products-details-tabs .content .products-reviews p {
    margin-bottom: 0;
}

.products-details-tabs .content .products-reviews .review-form {
    margin-top: 20px;
    max-width: 620px;
}

.products-details-tabs .content .products-reviews .review-form p {
    margin-bottom: 0;
}

.products-details-tabs .content .products-reviews .review-form .form-control {
    background: transparent;
}

.products-details-tabs .content .products-reviews .star-source {
    width: 0;
    height: 0;
    margin-top: 5px;
    visibility: hidden;
}

.products-details-tabs .content .products-reviews .star-source svg {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0 0.15rem;
}

.products-details-tabs .content .products-reviews .star-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 105px;
    margin-bottom: 10px;
}

.products-details-tabs .content .products-reviews .star-rating label {
    cursor: pointer;
}

.products-details-tabs .content .products-reviews .star-rating label .star {
    color: transparent;
    -webkit-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
}

.products-details-tabs .content .products-reviews .star-rating label:hover~label .star, .products-details-tabs .content .products-reviews .star-rating svg.star:hover, .products-details-tabs .content .products-reviews .star-rating input[name="star"]:focus~label .star, .products-details-tabs .content .products-reviews .star-rating input[name="star"]:checked~label .star {
    color: #f2b01e;
}

.products-details-tabs .content .products-reviews .star-rating svg {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0 0.15rem;
}

.products-details-tabs .content .products-reviews .star-rating input[name="star"] {
    display: inline-block;
    width: 0;
    opacity: 0;
    margin-left: -2px;
}

.products-details-tabs .content .products-reviews .star-rating input[name="star"]:checked+label {
    -webkit-animation: scaleup 1s;
    animation: scaleup 1s;
}

.products-details-tabs .content .products-reviews .star-rating input[name="star"]:checked+label .star {
    -webkit-animation: starred 0.5s;
    animation: starred 0.5s;
}

@-webkit-keyframes scaleup {
    from {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes scaleup {
    from {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    to {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes starred {
    from {
        color: #f2b01e;
    }

    to {
        color: #f2b01e;
    }
}

@keyframes starred {
    from {
        color: #f2b01e;
    }

    to {
        color: #f2b01e;
    }
}

/*================================================
Cart CSS
=================================================*/

.cart-table table {
    margin-bottom: 0;
}

.cart-table table thead tr th {
    border-bottom-width: 0px;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    padding: 15px 0;
    font-size: 15px;
    border: none;
}

.cart-table table tbody tr td {
    vertical-align: middle;
    color: #804e4e;
    padding-left: 20px;
    padding-right: 20px;
    border-color: #eaedff;
    /*border-left: none;
  border-right: none;*/
}

.cart-table table tbody tr td.product-thumbnail a {
    display: block;
}

.cart-table table tbody tr td.product-thumbnail a img {
    width: 120px;
}

.cart-table table tbody tr td.product-name a {
    color: #a46060;
    font-weight: 500;
    display: inline-block;
}

.cart-table table tbody tr td.product-name a:hover {
    color:
        /*#c03838*/
        #981914;
}

.cart-table table tbody tr td.product-subtotal .remove {
    border: none;
    /*padding: 0px;*/
    text-decoration: none;
    color:
        /*#c03838*/
        #981914;
    cursor: pointer;
    position: relative;
    top: -1px;
    float: right;
    margin-left: 10px;
    border-radius: 20px;
}

.cart-table table tbody tr td.product-subtotal .remove:hover {
    transition: 0.5s;
    color: #ffffff;
    border-radius: 20px;
    background-color:
        /*#c03838*/
        #981914;
}

.cart-table table tbody tr td.product-subtotal .remove:disabled {
    color: #ffffff;
    border-radius: 20px;
}

.cart-table table tbody tr td.product-subtotal .remove:disabled:hover {
    color: #ffffff;
    border-radius: 20px;
}

.cart-table table tbody tr td.product-subtotal .remove svg {
    width: 20px;
}

.cart-table table tbody tr td.product-quantity .input-counter {
    max-width: 120px;
    min-width: 120px;
    text-align: center;
    display: inline-block;
    position: relative;
}

.input-counter span {
    background-color: transparent;
    cursor: pointer;
    color: #bc2424;
    width: 40px;
    height: 100%;
    line-height: 47px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.cart-table table tbody tr td.product-quantity .input-counter span {
    position: absolute;
    top: 0;
    background-color: transparent;
    cursor: pointer;
    color: #bc2424;
    width: 40px;
    height: 100%;
    line-height: 47px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.cart-table table tbody tr td.product-quantity .input-counter span.minus-btn {
    left: 0;
    border-radius: 20px;
}

.cart-table table tbody tr td.product-quantity .input-counter span.plus-btn {
    right: 0;
    border-radius: 20px;
}

.cart-table table tbody tr td.product-quantity .input-counter span svg {
    width: 20px;
    border-radius: 20px;
}

.cart-table table tbody tr td.product-quantity .input-counter span:hover {
    color: #ffffff;
    border-radius: 20px;
    background-color: #bc2424;
}

.cart-table table tbody tr td.product-quantity .input-counter input {
    height: 45px;
    color: #0e314c;
    outline: 0;
    display: block;
    border: none;
    background-color: #f8f8f8;
    text-align: center;
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    border-radius: 20px;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
    color: #0e314c;
}

.cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
    color: #0e314c;
}

.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
    color: #0e314c;
}

.cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
    color: #0e314c;
}

.cart-buttons {
    margin-top: 30px;
}

.cart-buttons .btn-light {
    background-color: #ffeaea;
}

.cart-buttons .btn-light:hover, .cart-buttons .btn-light:focus {
    color: #ffffff;
}

.cart-buttons .btn-light:hover:not(:disabled):not(.disabled).active, .cart-buttons .btn-light:hover:not(:disabled):not(.disabled):active, .cart-buttons .btn-light:hover .show>.btn-light.dropdown-toggle, .cart-buttons .btn-light:focus:not(:disabled):not(.disabled).active, .cart-buttons .btn-light:focus:not(:disabled):not(.disabled):active, .cart-buttons .btn-light:focus .show>.btn-light.dropdown-toggle {
    color: #ffffff;
}

.cart-buttons .btn-light:focus {
    color: #ffffff;
}

.cart-totals {
    background: #ffffff;
    padding: 40px;
    max-width: 600px;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
}

.cart-totals h3 {
    font-size: 18px;
    margin-bottom: 25px;
}

.cart-totals ul {
    padding: 0;
    margin: 0 0 25px;
    list-style-type: none;
}

.cart-totals ul li {
    border: 1px solid #eaedff;
    padding: 10px 15px;
    color: #0e314c;
    overflow: hidden;
}

.cart-totals ul li:first-child {
    border-bottom: none;
}

.cart-totals ul li:last-child {
    border-top: none;
}

.cart-totals ul li span {
    float: right;
    color: #6084a4;
}

/*================================================
Checkout CSS
=================================================*/

.checkout-area .contenedor-tipo-pago {
    border-top: 2px solid rgba(255, 108, 108, 0.692);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.checkout-area .contenedor-tipo-pago select, .checkout-area .contenedor-tipo-pago input {
    background-color: #eeeeee;
}

.user-actions {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: #ffffff;
    padding: 18px 25px 15px;
    margin-bottom: 35px;
    border-top: 3px solid #44ce6f;
}

.user-actions svg {
    color: #44ce6f;
    margin-right: 4px;
    width: 16px;
}

.user-actions span {
    display: inline-block;
    color: #44ce6f;
}

.user-actions span a {
    font-weight: 500;
    display: inline-block;
    color: #0e314c;
}

.user-actions span a:hover, .user-actions span a:focus {
    color: #44ce6f;
}

.checkout-area .title {
    font-size: 18px;
    margin-bottom: 25px;
}

.billing-details {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: #ffffff;
    padding: 25px;
    border-radius: 5px;
}

.billing-details .form-group label {
    display: block;
    color: #6084a4;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
}

.billing-details .form-group label .required {
    color: red;
}

.billing-details .form-group .form-control {
    background: transparent;
}

.billing-details .form-group select {
    cursor: pointer;
}

.billing-details .form-check {
    margin-bottom: 15px;
}

.billing-details .form-check .form-check-label {
    color: #6084a4;
}

.billing-details .form-check label {
    font-size: 14px;
    font-weight: 400;
}

.order-details {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: #ffffff;
    padding: 25px;
    border-radius: 5px;
}

.order-details .order-table table {
    margin-bottom: 0;
    text-align: left;
}

.order-details .order-table table thead tr th {
    border-bottom-width: 0px;
    vertical-align: middle;
    font-weight: 500;
    border-color: #eaedff;
    padding: 15px 20px;
}

.order-details .order-table table tbody tr td {
    vertical-align: middle;
    color: #6084a4;
    border-color: #eaedff;
    font-size: 14px;
}

.order-details .order-table table tbody tr td.product-name a {
    color: #6084a4;
    display: inline-block;
}

.order-details .order-table table tbody tr td.product-name a:hover {
    color: #44ce6f;
}

.order-details .order-table table tbody tr td.order-subtotal span, .order-details .order-table table tbody tr td.order-shipping span, .order-details .order-table table tbody tr td.total-price span {
    color: #0e314c;
    font-weight: 500;
}

.order-details .payment-method {
    margin-top: 10px;
    border-top: 1px solid #eaedff;
    padding-top: 20px;
}

.order-details .payment-method p {
    font-size: 14px;
}

.order-details .payment-method p [type="radio"]:checked, .order-details .payment-method p [type="radio"]:not(:checked) {
    display: none;
}

.order-details .payment-method p [type="radio"]:checked+label, .order-details .payment-method p [type="radio"]:not(:checked)+label {
    padding-left: 27px;
    cursor: pointer;
    display: block;
    color: #0e314c;
    position: relative;
    margin-bottom: 8px;
    font-weight: 500;
}

.order-details .payment-method p [type="radio"]:checked+label::before, .order-details .payment-method p [type="radio"]:not(:checked)+label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid #dddddd;
    border-radius: 50%;
    background: #ffffff;
}

.order-details .payment-method p [type="radio"]:checked+label::after, .order-details .payment-method p [type="radio"]:not(:checked)+label::after {
    content: "";
    width: 12px;
    height: 12px;
    /* background: #44ce6f; */
    background: #ce4444;
    position: absolute;
    top: 7px;
    left: 3px;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.order-details .payment-method p [type="radio"]:not(:checked)+label::after {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.order-details .payment-method p [type="radio"]:checked+label::after {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.order-details .btn.order-btn {
    display: block;
    margin-top: 25px;
}

/*================================================
Page Title CSS
=================================================*/

.page-title-area {
    position: relative;
    z-index: 1;
    /* background: transparent url(../images/img/banner-bg1.jpg) right top
    no-repeat;*/
    padding-top: 190px;
    padding-bottom: 100px;
    text-align: center;
}

.page-title-area h2 {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
}

/*================================================
FAQ CSS
=================================================*/

.faq-accordion {
    -webkit-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
    padding: 60px;
    background: #ffffff;
    border-radius: 10px;
}

.faq-accordion .accordion {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.faq-accordion .accordion .accordion-item {
    display: block;
    margin-bottom: 15px;
}

.faq-accordion .accordion .accordion-item:last-child {
    margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item .accordion-title {
    display: block;
    padding: 12px 20px 12px 51px;
    text-decoration: none;
    color: #0e314c;
    position: relative;
    border-bottom: 1px solid #ffffff;
    background: #f5f9ff;
    font-size: 16px;
    font-weight: 500;
}

.faq-accordion .accordion .accordion-item .accordion-title i {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    text-align: center;
    height: 100%;
    background: #44ce6f;
    color: #ffffff;
}

.faq-accordion .accordion .accordion-item .accordion-title i::before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 15px;
}

.faq-accordion .accordion .accordion-item .accordion-title.active {
    border-bottom-color: #e1e1e1;
}

.faq-accordion .accordion .accordion-item .accordion-title.active i::before {
    content: "\f10f";
}

.faq-accordion .accordion .accordion-item .accordion-content {
    display: none;
    position: relative;
    overflow: hidden;
    padding: 15px 0 0;
    line-height: 1.9;
}

.faq-accordion .accordion .accordion-item .accordion-content.show {
    display: block;
}

.faq-contact {
    margin-top: 45px;
    -webkit-box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.08);
    padding: 60px;
    border-radius: 4px;
    background: #ffffff;
}

.faq-contact h3 {
    font-size: 24px;
    margin-bottom: 25px;
}

.faq-contact form .btn {
    margin-top: 10px;
}

/*================================================
Pagination CSS
=================================================*/

.pagination-area {
    text-align: center;
    margin-top: 30px;
}

.pagination-area ul {
    padding: 0;
    margin: 0;
}

.pagination-area ul .page-item .page-link {
    padding: 11px 20px;
    margin: 0 -1px;
    color: #6f8ba4;
    background-color: #ffffff;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
    box-shadow: 0 2px 10px 0 #d8dde6;
}

.pagination-area ul .page-item .page-link:hover, .pagination-area ul .page-item .page-link:focus {
    color: #ffffff;
    background-color: #44ce6f;
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.pagination-area ul .page-item .page-link svg {
    width: 16px;
}

.pagination-area ul .page-item.active .page-link {
    color: #ffffff;
    background-color: #44ce6f;
}

/*================================================
Blog Details CSS
=================================================*/

.blog-details-desc .article-content {
    margin-top: 30px;
}

.blog-details-desc .article-content .entry-meta {
    margin-bottom: -8px;
}

.blog-details-desc .article-content .entry-meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.blog-details-desc .article-content .entry-meta ul li {
    position: relative;
    display: inline-block;
    color: #0e314c;
    margin-right: 20px;
}

.blog-details-desc .article-content .entry-meta ul li a {
    display: inline-block;
    color: #6084a4;
}

.blog-details-desc .article-content .entry-meta ul li a:hover {
    color: #44ce6f;
}

.blog-details-desc .article-content .entry-meta ul li svg {
    color: #c679e3;
    margin-right: 2px;
    position: relative;
    top: -1px;
    width: 15px;
}

.blog-details-desc .article-content .entry-meta ul li::before {
    content: "";
    position: absolute;
    top: 12px;
    right: -15px;
    width: 6px;
    height: 1px;
    background: #44ce6f;
}

.blog-details-desc .article-content .entry-meta ul li:last-child {
    margin-right: 0;
}

.blog-details-desc .article-content .entry-meta ul li:last-child::before {
    display: none;
}

.blog-details-desc .article-content h3 {
    margin-bottom: 15px;
    margin-top: 25px;
    font-size: 20px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 {
    padding-left: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li {
    -ms-flex: 0 0 33.3333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding-right: 10px;
    padding-left: 10px;
}

.blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
    margin-bottom: 0;
}

.blog-details-desc .article-content .features-list {
    padding-left: 0;
    list-style-type: none;
    margin-top: 25px;
    margin-bottom: 30px;
}

.blog-details-desc .article-content .features-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 25px;
    color: #6084a4;
}

.blog-details-desc .article-content .features-list li svg {
    color: #44ce6f;
    display: inline-block;
    width: 20px;
    position: absolute;
    left: 0;
    top: 0;
}

.blog-details-desc .article-content .features-list li:last-child {
    margin-bottom: 0;
}

.blog-details-desc .article-footer {
    margin-top: 30px;
}

.blog-details-desc .article-footer .article-tags a {
    display: inline-block;
    color: #ffffff;
    background-color: #c679e3;
    padding: 4px 12px;
    border-radius: 5px;
    margin-right: 3px;
    font-size: 14px;
}

.blog-details-desc .article-footer .article-tags a:hover {
    color: #ffffff;
    background-color: #44ce6f;
}

.comments-area {
    margin-top: 25px;
}

.comments-area .comments-title {
    margin-bottom: 30px;
    line-height: initial;
    font-size: 20px;
}

.comments-area ol, .comments-area ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.comments-area .comment-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.comments-area .children {
    margin-left: 20px;
}

.comments-area .comment-body {
    border-bottom: 1px solid #eeeeee;
    padding-left: 65px;
    color: #0e314c;
    font-size: 14px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.comments-area .comment-body .reply {
    margin-top: 15px;
}

.comments-area .comment-body .reply a {
    border: 1px solid #ded9d9;
    color: #0e314c;
    display: inline-block;
    padding: 6px 20px;
    border-radius: 30px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
    font-size: 13px;
    font-weight: 500;
}

.comments-area .comment-body .reply a:hover {
    color: #ffffff;
    background-color: #44ce6f;
    border-color: #44ce6f;
}

.comments-area .comment-author {
    font-size: 16px;
    margin-bottom: 0.2em;
    position: relative;
    z-index: 2;
}

.comments-area .comment-author .avatar {
    height: 50px;
    left: -65px;
    position: absolute;
    width: 50px;
}

.comments-area .comment-author .fn {
    font-weight: 500;
}

.comments-area .comment-author .says {
    display: none;
}

.comments-area .comment-metadata {
    margin-bottom: 0.8em;
    color: #6084a4;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 400;
}

.comments-area .comment-metadata a {
    color: #6084a4;
}

.comments-area .comment-metadata a:hover {
    color: #44ce6f;
}

.comments-area .comment-respond {
    margin-top: 30px;
}

.comments-area .comment-respond .comment-reply-title {
    margin-bottom: 0;
    font-size: 20px;
}

.comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
    display: inline-block;
}

.comments-area .comment-respond .comment-form {
    overflow: hidden;
}

.comments-area .comment-respond .comment-notes {
    margin-bottom: 0;
    margin-top: 10px;
}

.comments-area .comment-respond .comment-notes .required {
    color: red;
}

.comments-area .comment-respond .comment-form-comment {
    margin-top: 15px;
    float: left;
    width: 100%;
}

.comments-area .comment-respond label {
    display: block;
    color: #0e314c;
    margin-bottom: 5px;
    font-size: 14px;
}

.comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
    display: block;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    padding: 0.625em 0.7375em;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.comments-area .comment-respond input[type="date"]:focus, .comments-area .comment-respond input[type="time"]:focus, .comments-area .comment-respond input[type="datetime-local"]:focus, .comments-area .comment-respond input[type="week"]:focus, .comments-area .comment-respond input[type="month"]:focus, .comments-area .comment-respond input[type="text"]:focus, .comments-area .comment-respond input[type="email"]:focus, .comments-area .comment-respond input[type="url"]:focus, .comments-area .comment-respond input[type="password"]:focus, .comments-area .comment-respond input[type="search"]:focus, .comments-area .comment-respond input[type="tel"]:focus, .comments-area .comment-respond input[type="number"]:focus, .comments-area .comment-respond textarea:focus {
    border-color: #44ce6f;
}

.comments-area .comment-respond .comment-form-author {
    float: left;
    width: 50%;
    padding-right: 10px;
    margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-email {
    float: left;
    width: 50%;
    padding-left: 12px;
    margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-url {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent {
    width: 100%;
    float: left;
    position: relative;
    padding-left: 20px;
    margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form-cookies-consent input {
    position: absolute;
    left: 0;
    top: 5px;
}

.comments-area .comment-respond .comment-form-cookies-consent label {
    display: inline-block;
    margin: 0;
    color: #6084a4;
    font-weight: normal;
    position: relative;
    top: -2px;
}

.comments-area .comment-respond .form-submit {
    float: left;
    width: 100%;
}

.comments-area .comment-respond .form-submit input {
    background: #44ce6f;
    border: none;
    color: #ffffff;
    padding: 11px 25px 10px;
    display: inline-block;
    cursor: pointer;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
}

.comments-area .comment-respond .form-submit input:hover, .comments-area .comment-respond .form-submit input:focus {
    color: #ffffff;
    background-color: #c679e3;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.startp-post-navigation {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
}

.prev-link-wrapper {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 15px;
}

.prev-link-wrapper a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.prev-link-wrapper a:hover .image-prev::after {
    opacity: 1;
    visibility: visible;
}

.prev-link-wrapper a:hover .image-prev .post-nav-title {
    opacity: 1;
    visibility: visible;
}

.prev-link-wrapper a:hover .prev-link-info-wrapper {
    color: #44ce6f;
}

.prev-link-wrapper .image-prev {
    display: inline-block;
    min-width: 100px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: top;
    margin-right: 20px;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.prev-link-wrapper .image-prev img {
    border-radius: 5px;
}

.prev-link-wrapper .image-prev::after {
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    opacity: 0;
    background-color: #44ce6f;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.prev-link-wrapper .image-prev .post-nav-title {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 16px;
    font-weight: 500;
}

.prev-link-wrapper .prev-link-info-wrapper {
    color: #0e314c;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.prev-link-wrapper .prev-title {
    display: inline-block;
    font-weight: 500;
    font-size: 17px;
}

.prev-link-wrapper .meta-wrapper {
    display: block;
    color: #6084a4;
    text-transform: capitalize;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
}

.next-link-wrapper {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
    padding-left: 15px;
}

.next-link-wrapper a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.next-link-wrapper a:hover .image-next::after {
    opacity: 1;
    visibility: visible;
}

.next-link-wrapper a:hover .image-next .post-nav-title {
    opacity: 1;
    visibility: visible;
}

.next-link-wrapper a:hover .next-link-info-wrapper {
    color: #44ce6f;
}

.next-link-wrapper .image-next {
    display: inline-block;
    min-width: 100px;
    width: 100px;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: top;
    margin-left: 20px;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.next-link-wrapper .image-next img {
    border-radius: 5px;
}

.next-link-wrapper .image-next::after {
    display: block;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    opacity: 0;
    background-color: #44ce6f;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.next-link-wrapper .image-next .post-nav-title {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    z-index: 2;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 16px;
    font-weight: 500;
}

.next-link-wrapper .next-link-info-wrapper {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #0e314c;
}

.next-link-wrapper .next-title {
    display: inline-block;
    font-weight: 500;
    font-size: 17px;
}

.next-link-wrapper .meta-wrapper {
    display: block;
    color: #6084a4;
    text-transform: capitalize;
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px;
}

blockquote, .blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    margin-top: 20px;
}

blockquote p, .blockquote p {
    color: #0e314c;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 20px !important;
}

blockquote cite, .blockquote cite {
    display: none;
}

blockquote::after, .blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #44ce6f;
    margin-top: 20px;
    margin-bottom: 20px;
}

/*================================================
Sidebar Widget CSS
=================================================*/

.widget-area .widget {
    margin-top: 35px;
}

.widget-area .widget:first-child {
    margin-top: 0;
}

.widget-area .widget .widget-title {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 10px;
    margin-bottom: 25px;
    position: relative;
    font-size: 19px;
}

.widget-area .widget .widget-title::before {
    content: "";
    position: absolute;
    background: #44ce6f;
    bottom: -1px;
    left: 0;
    width: 50px;
    height: 1px;
}

.widget-area .widget_search {
    -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    background-color: #ffffff;
    padding: 15px;
}

.widget-area .widget_search form {
    position: relative;
}

.widget-area .widget_search form label {
    display: block;
    margin-bottom: 0;
}

.widget-area .widget_search form .screen-reader-text {
    display: none;
}

.widget-area .widget_search form .search-field {
    background-color: transparent;
    height: 50px;
    padding: 6px 15px;
    border: 1px solid #eeeeee;
    width: 100%;
    display: block;
    outline: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.widget-area .widget_search form .search-field:focus {
    border-color: #44ce6f;
}

.widget-area .widget_search form button {
    position: absolute;
    right: 0;
    outline: 0;
    bottom: 0;
    height: 50px;
    width: 50px;
    z-index: 1;
    border: none;
    cursor: pointer;
    color: #ffffff;
    background-color: #44ce6f;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 20px;
}

.widget-area .widget_search form button svg {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    left: 0;
    width: 20px;
    margin-left: auto;
    margin-right: auto;
}

.widget-area .widget_search form button:hover {
    background-color: #c679e3;
    color: #ffffff;
}

.widget-area .widget_startp_posts_thumb {
    position: relative;
    overflow: hidden;
}

.widget-area .widget_startp_posts_thumb .item {
    overflow: hidden;
    margin-bottom: 15px;
}

.widget-area .widget_startp_posts_thumb .item:last-child {
    margin-bottom: 0;
}

.widget-area .widget_startp_posts_thumb .item .thumb {
    float: left;
    height: 80px;
    overflow: hidden;
    position: relative;
    width: 80px;
    margin-right: 15px;
}

.widget-area .widget_startp_posts_thumb .item .thumb .fullimage {
    width: 80px;
    height: 80px;
    display: inline-block;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center !important;
}

.widget-area .widget_startp_posts_thumb .item .thumb .fullimage.bg1 {
    background-image: url(../images/img/blog-image/7.jpg);
}

.widget-area .widget_startp_posts_thumb .item .thumb .fullimage.bg2 {
    background-image: url(../images/img/blog-image/8.jpg);
}

.widget-area .widget_startp_posts_thumb .item .thumb .fullimage.bg3 {
    background-image: url(../images/img/blog-image/9.jpg);
}

.widget-area .widget_startp_posts_thumb .item .info {
    overflow: hidden;
    margin-top: 5px;
}

.widget-area .widget_startp_posts_thumb .item .info time {
    display: block;
    color: #6084a4;
    text-transform: uppercase;
    margin-top: 3px;
    margin-bottom: 5px;
    font-size: 12px;
}

.widget-area .widget_startp_posts_thumb .item .info .title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 15px;
    font-weight: 500;
}

.widget-area .widget_startp_posts_thumb .item .info .title a {
    display: inline-block;
}

.widget-area .widget_recent_entries ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_recent_entries ul li {
    position: relative;
    margin-bottom: 12px;
    color: #0e314c;
    padding-left: 14px;
    line-height: 1.5;
    font-weight: 500;
    font-size: 14px;
}

.widget-area .widget_recent_entries ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_recent_entries ul li::before {
    background: #44ce6f;
    position: absolute;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 8px;
}

.widget-area .widget_recent_entries ul li a {
    display: inline-block;
    color: #0e314c;
}

.widget-area .widget_recent_entries ul li a:hover {
    color: #44ce6f;
}

.widget-area .widget_recent_entries ul li .post-date {
    display: block;
    font-size: 13px;
    color: #6084a4;
    margin-top: 4px;
}

.widget-area .widget_recent_comments ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_recent_comments ul li {
    position: relative;
    margin-bottom: 12px;
    color: #6084a4;
    padding-left: 14px;
    line-height: 1.5;
    font-weight: 500;
    font-size: 14px;
}

.widget-area .widget_recent_comments ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_recent_comments ul li::before {
    background: #44ce6f;
    position: absolute;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 8px;
}

.widget-area .widget_recent_comments ul li a {
    display: inline-block;
    color: #0e314c;
}

.widget-area .widget_recent_comments ul li a:hover {
    color: #44ce6f;
}

.widget-area .widget_archive ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_archive ul li {
    position: relative;
    margin-bottom: 12px;
    padding-left: 14px;
    color: #0e314c;
    font-weight: 500;
    font-size: 14px;
}

.widget-area .widget_archive ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_archive ul li::before {
    background: #44ce6f;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 8px;
    position: absolute;
}

.widget-area .widget_archive ul li a {
    display: inline-block;
    color: #0e314c;
}

.widget-area .widget_archive ul li a:hover {
    color: #44ce6f;
}

.widget-area .widget_categories ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_categories ul li {
    position: relative;
    margin-bottom: 12px;
    color: #6084a4;
    padding-left: 14px;
    font-weight: 500;
    font-size: 14px;
}

.widget-area .widget_categories ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_categories ul li::before {
    background: #44ce6f;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 8px;
    position: absolute;
}

.widget-area .widget_categories ul li a {
    color: #0e314c;
    display: block;
}

.widget-area .widget_categories ul li a:hover {
    color: #44ce6f;
}

.widget-area .widget_categories ul li .post-count {
    float: right;
}

.widget-area .widget_meta ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}

.widget-area .widget_meta ul li {
    position: relative;
    margin-bottom: 12px;
    color: #0e314c;
    padding-left: 14px;
    font-weight: 500;
    font-size: 14px;
}

.widget-area .widget_meta ul li:last-child {
    margin-bottom: 0;
}

.widget-area .widget_meta ul li::before {
    background: #44ce6f;
    height: 7px;
    width: 7px;
    content: "";
    left: 0;
    top: 8px;
    position: absolute;
}

.widget-area .widget_meta ul li a {
    display: inline-block;
    color: #0e314c;
}

.widget-area .widget_meta ul li a:hover {
    color: #44ce6f;
}

.widget-area .tagcloud a {
    display: inline-block;
    color: #0e314c;
    font-weight: 500;
    font-size: 14px !important;
    padding: 6px 13px;
    border: 1px dashed #eeeeee;
    margin-top: 8px;
    margin-right: 4px;
}

.widget-area .tagcloud a:hover, .widget-area .tagcloud a:focus {
    color: #ffffff;
    background-color: #44ce6f;
    border-color: #44ce6f;
}

/*================================================
Project Details CSS
=================================================*/

.project-details-image {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    text-align: center;
}

.project-details-image::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0e314c;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.project-details-image a {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    color: #ffffff;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    margin-top: 25px;
}

.project-details-image a:hover {
    color: #44ce6f;
}

.project-details-image:hover::before {
    opacity: 0.9;
    visibility: visible;
}

.project-details-image:hover a {
    opacity: 1;
    visibility: visible;
    margin-top: -5px;
}

.project-details-desc h3 {
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.project-details-desc .project-details-information {
    overflow: hidden;
}

.project-details-desc .project-details-information .single-info-box {
    float: left;
    width: 20%;
    margin-top: 30px;
}

.project-details-desc .project-details-information .single-info-box h4 {
    font-size: 18px;
    margin-bottom: 15px;
}

.project-details-desc .project-details-information .single-info-box p {
    margin-bottom: 0;
}

.project-details-desc .project-details-information .single-info-box ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.project-details-desc .project-details-information .single-info-box ul li {
    display: inline-block;
}

.project-details-desc .project-details-information .single-info-box ul li a {
    margin-right: 4px;
    color: #6084a4;
}

.project-details-desc .project-details-information .single-info-box ul li a svg {
    width: 18px;
}

.project-details-desc .project-details-information .single-info-box ul li a:hover {
    color: #44ce6f;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

/*================================================
Contact CSS
=================================================*/

.contact-info-box {
    text-align: center;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: #ffffff;
    padding: 30px;
}

.contact-info-box .icon {
    display: inline-block;
    width: 65px;
    height: 65px;
    line-height: 62px;
    background: #f7fafd;
    border-radius: 50%;
    font-size: 30px;
    color: #bc2424;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.contact-info-box h3 {
    font-size: 18px;
    margin: 25px 0 15px;
}

.contact-info-box p {
    margin-bottom: 0;
}

.contact-info-box p a {
    color: #6084a4;
}

.contact-info-box p a:hover {
    color: #44ce6f;
}

.contact-info-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.contact-info-box:hover .icon {
    color: #ffffff;
    background: #bc2424;
}

#map {
    height: 500px;
    width: 100%;
}

#contactForm .help-block ul {
    margin: 0;
}

#contactForm .help-block ul li {
    color: red;
}

#contactForm .btn {
    margin-top: 10px;
}

#contactForm #msgSubmit.text-danger, #contactForm #msgSubmit.text-success {
    margin: 20px 0 0;
}

/*================================================
Coming Soon CSS
=================================================*/

.coming-soon-area {
    position: relative;
    z-index: 1;
    height: 100vh;
    background-image: url(../images/img/coming-soon.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.coming-soon-area::before {
    z-index: -1;
    background: linear-gradient(-9deg, #6a82fb 0%, #6a82fb 40%, #fc5c7d 100%);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.8;
}

.coming-soon-area .social-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    bottom: 30px;
}

.coming-soon-area .social-list li {
    display: inline-block;
}

.coming-soon-area .social-list li.list-heading {
    display: block;
    color: #ffffff;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
}

.coming-soon-area .social-list li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #44ce6f;
    color: #ffffff;
    border-radius: 50%;
    margin: 0 2px;
}

.coming-soon-area .social-list li a svg {
    width: 15px;
}

.coming-soon-area .social-list li a.facebook {
    background-color: #3b5998;
}

.coming-soon-area .social-list li a.twitter {
    background-color: #1da1f2;
}

.coming-soon-area .social-list li a.linkedin {
    background-color: #0077b5;
}

.coming-soon-area .social-list li a.instagram {
    background-color: #c13584;
}

.coming-soon-area .social-list li a:hover, .coming-soon-area .social-list li a:focus {
    background-color: #44ce6f;
}

.coming-soon-content {
    text-align: center;
    max-width: 820px;
    margin: -80px auto 0;
}

.coming-soon-content h1 {
    color: #ffffff;
    font-size: 55px;
    font-weight: 600;
    margin-bottom: 15px;
}

.coming-soon-content p {
    color: #ffffff;
    font-size: 16px;
    margin: 0 auto;
    max-width: 620px;
}

.coming-soon-content form {
    position: relative;
    margin: 35px auto 55px;
    max-width: 520px;
}

.coming-soon-content form .email-input {
    display: block;
    width: 100%;
    height: 56px;
    border: none;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 5px rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    padding: 15px 25px;
    font-size: 16px;
    outline: 0 !important;
    background: #f1f2f3;
}

.coming-soon-content form .submit-btn {
    position: absolute;
    right: 3px;
    top: 3px;
    height: 50px;
    background: #44ce6f;
    color: #ffffff;
    border: none;
    border-radius: 40px;
    width: 130px;
    outline: 0 !important;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 15px;
    font-weight: 500;
}

.coming-soon-content form .submit-btn:hover, .coming-soon-content form .submit-btn:focus {
    background-color: #c679e3;
}

.coming-soon-content #timer div {
    background: #ffffff;
    display: inline-block;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    color: #c679e3;
    -webkit-box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.5);
    margin: 0 10px;
    padding-top: 18px;
    font-size: 35px;
    font-weight: 500;
}

.coming-soon-content #timer div span {
    display: block;
    margin-top: -4px;
    color: #6084a4;
    font-size: 15px;
}

/*================================================
404 Error CSS
=================================================*/

.error-area {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 99990;
    background-color: #ffffff;
}

.error-content {
    text-align: center;
    margin-top: -70px;
}

.error-content .notfound-404 {
    position: relative;
    height: 280px;
    z-index: -1;
    margin-bottom: 30px;
}

.error-content .notfound-404 h1 {
    font-size: 230px;
    font-weight: 900;
    margin: 0;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background: url(../images/img/oops-bg.jpg) no-repeat;
    /*-webkit-*/
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: cover;
    background-position: center;
}

.error-content h3 {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 15px;
}

.error-content p {
    max-width: 550px;
    margin: 0 auto 25px;
}

/*================================================
CTA CSS
=================================================*/

.cta-area {
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
}

.cta-area .container {
    max-width: 945px;
}

.cta-content h3 {
    color: #ffffff;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
}

.cta-right-content {
    text-align: right;
}

.cta-right-content .hosting-price {
    display: inline-block;
}

.cta-right-content .hosting-price span {
    color: #f6fe6a;
    display: block;
}

.cta-right-content .hosting-price h4 {
    margin: 10px 0 0;
    color: #ffffff;
    font-size: 20px;
}

.cta-right-content .buy-btn {
    display: inline-block;
    padding-left: 35px;
    position: relative;
    top: -15px;
}

.cta-right-content .buy-btn .btn-primary {
    background: #c679e3;
    -webkit-box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
    box-shadow: 0 13px 27px 0 rgba(198, 121, 227, 0.25);
}

.cta-right-content .buy-btn .btn-primary::after, .cta-right-content .buy-btn .btn-primary::before {
    background: #ffffff;
    -webkit-box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
    box-shadow: 0 13px 27px 0 rgba(68, 206, 111, 0.25);
}

.cta-right-content .buy-btn .btn-primary:hover, .cta-right-content .buy-btn .btn-primary:focus {
    color: #44ce6f;
}

/*================================================
Repair Main Banner CSS
=================================================*/

.repair-main-banner {
    overflow-x: hidden;
    padding-top: 200px;
    padding-bottom: 140px;
}

.repair-banner-content h1 {
    margin-bottom: 20px;
    font-size: 45px;
    font-weight: 500;
}

.repair-banner-content p {
    margin-bottom: 30px;
}

.repair-banner-image {
    position: relative;
    text-align: center;
}

.repair-banner-image img:nth-child(2) {
    position: absolute;
    left: 0;
    top: -65px;
    right: -30px;
    margin: 0 auto;
    z-index: -1;
}

.repair-banner-image img:nth-child(3) {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -69px;
    z-index: -2;
}

.repair-banner-image img:nth-child(4) {
    position: absolute;
    left: 0;
    right: 0;
    top: -30px;
    z-index: -1;
    margin: 0 auto;
}

/*================================================
Repair Partner CSS
=================================================*/

.repair-partner-area {
    padding-top: 45px;
    padding-bottom: 45px;
}

.single-repair-partner {
    text-align: center;
}

.single-repair-partner a {
    display: block;
    position: relative;
}

.single-repair-partner a img {
    width: auto !important;
    display: inline-block !important;
}

.single-repair-partner a img:last-child {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.single-repair-partner a:hover img:last-child, .single-repair-partner a:focus img:last-child {
    opacity: 1;
    visibility: visible;
    top: 0;
}

.partner-slides.owl-theme .owl-dots {
    margin-top: 0;
}

/*================================================
Repair About CSS
=================================================*/

.repair-about-content .sub-title {
    text-transform: uppercase;
    color: #44ce6f;
    display: block;
    font-size: 15px;
    font-weight: 400;
}

.repair-about-content h2 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
}

.repair-about-content ul {
    padding: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -10px;
    margin-right: -10px;
    margin-left: -10px;
}

.repair-about-content ul li {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    width: 100%;
    margin-top: 25px;
    color: #6084a4;
    position: relative;
    padding-right: 10px;
    padding-left: 10px;
}

.repair-about-content ul li span {
    display: block;
    position: relative;
    z-index: 1;
    padding: 10px;
    border-radius: 3px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
}

.repair-about-content ul li span svg {
    margin-right: 5px;
    display: inline-block;
    color: #44ce6f;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 18px;
}

.repair-about-content ul li span::before {
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    border-radius: 3px;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.repair-about-content ul li span:hover, .repair-about-content ul li span:focus {
    color: #ffffff;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.repair-about-content ul li span:hover::before, .repair-about-content ul li span:focus::before {
    opacity: 1;
    visibility: visible;
}

.repair-about-content ul li span:hover svg, .repair-about-content ul li span:focus svg {
    color: #ffffff;
}

.repair-about-image {
    position: relative;
    width: 100%;
    height: 100%;
}

.repair-about-image img {
    position: absolute;
}

.repair-about-image img:nth-child(1) {
    left: 0;
    top: 0;
    z-index: 1;
}

.repair-about-image img:nth-child(2) {
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    text-align: center;
    z-index: 2;
    margin: 0 auto;
    border: 5px solid #f8fbff;
    width: 350px;
}

.repair-about-image img:nth-child(3) {
    right: 0;
    bottom: 0;
    z-index: 1;
}

.repair-about-image img:nth-child(4) {
    right: 0;
    top: 0;
}

.repair-about-image img:nth-child(5) {
    left: 0;
    bottom: 0;
}

/*================================================
Repair Services CSS
=================================================*/

.repair-services-area {
    padding-bottom: 50px;
}

.single-repair-services {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    padding: 40px 30px;
    text-align: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.single-repair-services.bg1 {
    background-image: url(../images/img/repair-services-img/1.jpg);
}

.single-repair-services.bg2 {
    background-image: url(../images/img/repair-services-img/2.jpg);
}

.single-repair-services.bg3 {
    background-image: url(../images/img/repair-services-img/3.jpg);
}

.single-repair-services.bg4 {
    background-image: url(../images/img/repair-services-img/4.jpg);
}

.single-repair-services.bg5 {
    background-image: url(../images/img/repair-services-img/5.jpg);
}

.single-repair-services.bg6 {
    background-image: url(../images/img/repair-services-img/6.jpg);
}

.single-repair-services::before {
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    z-index: -1;
    border: 1px dashed #cdf1d8;
}

.single-repair-services::after {
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    border: 1px dashed #cdf1d8;
}

.single-repair-services h3 {
    font-size: 18px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-top: 22px;
    margin-bottom: 17px;
}

.single-repair-services .icon {
    display: inline-block;
    color: #44ce6f;
    width: 85px;
    height: 85px;
    line-height: 85px;
    border: 1px solid #e3f7e9;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-repair-services .icon i::before {
    font-size: 35px;
}

.single-repair-services p {
    margin-bottom: 25px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-repair-services a {
    width: 35px;
    background-color: #f9fafb;
    height: 35px;
    line-height: 35px;
    color: #0e314c;
    border-radius: 50%;
}

.single-repair-services a svg {
    width: 15px;
}

.single-repair-services:hover::before, .single-repair-services:focus::before {
    opacity: 0;
    visibility: hidden;
}

.single-repair-services:hover::after, .single-repair-services:focus::after {
    opacity: 0.85;
    visibility: visible;
}

.single-repair-services:hover h3, .single-repair-services:focus h3 {
    color: #ffffff;
}

.single-repair-services:hover p, .single-repair-services:focus p {
    color: #ffffff;
}

.single-repair-services:hover .icon, .single-repair-services:focus .icon {
    border-color: #ffffff;
    color: #ffffff;
}

.single-repair-services:hover a, .single-repair-services:focus a {
    background-color: #ffffff;
    color: #44ce6f;
}

/*================================================
Repair CTA CSS
=================================================*/

.repair-cta-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cta-repair-content h3 {
    color: #ffffff;
    font-size: 25px;
    margin-bottom: 17px;
}

.cta-repair-content p {
    color: #ffffff;
    opacity: 0.95;
    margin-bottom: 25px;
}

.cta-repair-img {
    text-align: right;
}

.circle-box {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.cta-shape {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}

/*================================================
Repair Team CSS
=================================================*/

.repair-team-area .container {
    max-width: 1175px;
}

.repair-team-area .single-team .team-content {
    padding-left: 25px;
    padding-right: 25px;
}

/*================================================
Repair Why Choose Us CSS
=================================================*/

.repair-why-choose-us {
    position: relative;
    z-index: 1;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
}

.repair-why-choose-us .section-title h2 {
    color: #ffffff;
}

.repair-why-choose-us .section-title .bar::before {
    background: #ffffff;
}

.repair-why-choose-us .section-title p {
    color: #ffffff;
    opacity: 0.95;
}

.repair-why-choose-us::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 37%;
    width: 100%;
    background: #ffffff;
    z-index: -1;
}

.single-repair-box {
    text-align: center;
    background-color: #ffffff;
    border: 1px dashed #cdf1d8;
    padding: 30px;
    position: relative;
    z-index: 1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-repair-box h3 {
    font-size: 18px;
    margin-top: 22px;
    margin-bottom: 17px;
}

.single-repair-box p {
    margin-bottom: 25px;
}

.single-repair-box a {
    width: 40px;
    background-color: #eef0f3;
    height: 40px;
    line-height: 40px;
    color: #0e314c;
    border-radius: 50%;
}

.single-repair-box a svg {
    width: 18px;
}

.single-repair-box .icon {
    display: inline-block;
    border: 1px solid #6ee9fa;
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 50%;
    position: relative;
    color: #ffffff;
    z-index: 1;
}

.single-repair-box .icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    background: -webkit-gradient(linear, left top, right bottom, from(#1fa2ff), color-stop(#00c0ff), color-stop(#00d9f2), color-stop(#53eedd), to(#a6ffcb));
    background: linear-gradient(to right bottom, #1fa2ff, #00c0ff, #00d9f2, #53eedd, #a6ffcb);
    border-radius: 50%;
    margin: 6px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-repair-box .icon i::before {
    font-size: 35px;
}

.single-repair-box .back-icon {
    position: absolute;
    top: 58%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #4457c3;
    opacity: 0.06;
    z-index: -1;
}

.single-repair-box .back-icon i::before {
    font-size: 200px;
}

.single-repair-box:hover, .single-repair-box:focus {
    border-color: #44ce6f;
}

.single-repair-box:hover a, .single-repair-box:focus a {
    background-color: #44ce6f;
    color: #ffffff;
}

/*================================================
Repair Feedback CSS
=================================================*/

.single-repair-feedback {
    background: #ffffff;
    border-radius: 5px;
    margin-bottom: 55px;
    position: relative;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.single-repair-feedback .client-img {
    position: relative;
    text-align: left;
    margin-bottom: 40px;
    display: inline-block;
    padding-left: 108px;
    padding-top: 18px;
}

.single-repair-feedback .client-img img {
    border-radius: 50%;
    border: 2px solid #44ce6f;
    padding: 3px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    width: 90px;
    height: 90px;
}

.single-repair-feedback .client-img h3 {
    font-size: 18px;
    margin: 0;
}

.single-repair-feedback .client-img span {
    display: block;
    color: #c679e3;
    margin-top: 8px;
}

.single-repair-feedback p {
    font-size: 15px;
}

.single-repair-feedback::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -25px;
    width: 50px;
    height: 50px;
    background: #ffffff;
    right: 0;
    margin: 0 auto;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.single-repair-feedback::after {
    content: "\f11b";
    color: #d9dcde;
    font-family: Flaticon;
    font-size: 30px;
    font-style: normal;
    font-size: 65px;
    position: absolute;
    top: 0;
    left: 25px;
}

/*================================================
IoT Main Banner CSS
=================================================*/

.iot-main-banner {
    position: relative;
    z-index: 1;
    padding-top: 215px;
    padding-bottom: 150px;
}

.iot-banner-content {
    max-width: 445px;
}

.iot-banner-content span {
    color: #44ce6f;
    font-size: 15px;
    display: block;
}

.iot-banner-content h2 {
    font-size: 40px;
    margin-top: 7px;
    margin-bottom: 17px;
    line-height: 1.3;
}

.iot-banner-content .btn {
    margin-top: 10px;
}

.iot-banner-image {
    position: absolute;
    text-align: center;
    right: 30px;
    top: 56%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.iot-banner-image img:first-child {
    max-width: 730px;
}

.iot-banner-image img:last-child {
    position: absolute;
    left: -118px;
    right: 0;
    margin: 0 auto;
    top: 39px;
    z-index: -1;
}

.animate-border span {
    position: absolute;
    display: block;
    width: 5%;
    padding-bottom: 5%;
    top: 51%;
    left: 67%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: none;
    border-radius: 50%;
    z-index: -2;
}

.animate-border span:nth-child(1) {
    -webkit-animation: 4.2s ease-out 0.5s infinite normal none running pulsei;
    animation: 4.2s ease-out 0.5s infinite normal none running pulsei;
}

.animate-border span:nth-child(2) {
    -webkit-animation: 4.2s ease-out 1.5s infinite normal none running pulsei;
    animation: 4.2s ease-out 1.5s infinite normal none running pulsei;
}

.animate-border span:nth-child(3) {
    -webkit-animation: 4.2s ease-out 2.5s infinite normal none running pulsei;
    animation: 4.2s ease-out 2.5s infinite normal none running pulsei;
}

.animate-border span:nth-child(4) {
    -webkit-animation: 4.2s ease-out 3.5s infinite normal none running pulsei;
    animation: 4.2s ease-out 3.5s infinite normal none running pulsei;
}

@-webkit-keyframes pulsei {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        border: 1.5px solid #b3eaf8;
        opacity: 1;
        width: 5%;
        padding-bottom: 5%;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 0;
        width: 50%;
        border: 1.5px solid #b3eaf8;
        padding-bottom: 50%;
    }
}

@keyframes pulsei {
    0% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        border: 1.5px solid #b3eaf8;
        opacity: 1;
        width: 5%;
        padding-bottom: 5%;
    }

    100% {
        -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 0;
        width: 50%;
        border: 1.5px solid #b3eaf8;
        padding-bottom: 50%;
    }
}

/*================================================
IoT Partner CSS
=================================================*/

.iot-partner-area {
    padding-top: 45px;
    padding-bottom: 45px;
}

.single-iot-partner {
    text-align: center;
}

.single-iot-partner a {
    display: block;
    position: relative;
}

.single-iot-partner a img {
    width: auto !important;
    display: inline-block !important;
}

.single-iot-partner a img:last-child {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.single-iot-partner a:hover img:last-child, .single-iot-partner a:focus img:last-child {
    opacity: 1;
    visibility: visible;
    top: 0;
}

/*================================================
Iot Services CSS
=================================================*/

.iot-services-area {
    padding-bottom: 50px;
}

.single-iot-services {
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    padding: 40px 30px;
    text-align: center;
    background-color: #ffffff;
    border: 1px dashed #cdf1d8;
}

.single-iot-services::after {
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
}

.single-iot-services h3 {
    font-size: 18px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-top: 22px;
    margin-bottom: 17px;
    line-height: 1.5;
}

.single-iot-services .icon {
    display: inline-block;
    color: #44ce6f;
    width: 85px;
    height: 85px;
    line-height: 85px;
    border: 1px solid #e3f7e9;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-iot-services .icon i::before {
    font-size: 35px;
}

.single-iot-services p {
    margin-bottom: 25px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-iot-services a {
    width: 35px;
    background-color: #f9fafb;
    height: 35px;
    line-height: 35px;
    color: #0e314c;
    border-radius: 50%;
}

.single-iot-services a svg {
    width: 15px;
}

.single-iot-services:hover::after, .single-iot-services:focus::after {
    opacity: 1;
    visibility: visible;
}

.single-iot-services:hover h3, .single-iot-services:focus h3 {
    color: #ffffff;
}

.single-iot-services:hover p, .single-iot-services:focus p {
    color: #ffffff;
}

.single-iot-services:hover .icon, .single-iot-services:focus .icon {
    border-color: #ffffff;
    color: #ffffff;
}

.single-iot-services:hover a, .single-iot-services:focus a {
    background-color: #ffffff;
    color: #44ce6f;
}

/*================================================
IoT CTA CSS
=================================================*/

.iot-cta-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    padding-top: 10px;
    padding-bottom: 10px;
}

.cta-iot-content h3 {
    color: #ffffff;
    font-size: 25px;
    margin-bottom: 17px;
}

.cta-iot-content p {
    color: #ffffff;
    opacity: 0.95;
    margin-bottom: 25px;
}

.cta-iot-img {
    text-align: center;
}

/*================================================
IoT Features CSS
=================================================*/

.iot-features-content h3 {
    font-size: 26px;
    margin-bottom: 20px;
}

.iot-features-content .btn {
    margin-top: 15px;
}

.iot-features-image {
    position: relative;
    text-align: center;
}

/*================================================
IoT Team CSS
=================================================*/

.iot-team-area .container {
    max-width: 1175px;
}

.iot-team-area .single-team .team-content {
    padding-left: 25px;
    padding-right: 25px;
}

/*================================================
IoT Why Choose Us CSS
=================================================*/

.iot-why-choose-us {
    position: relative;
    z-index: 1;
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
}

.iot-why-choose-us .section-title h2 {
    color: #ffffff;
}

.iot-why-choose-us .section-title .bar::before {
    background: #ffffff;
}

.iot-why-choose-us .section-title p {
    color: #ffffff;
    opacity: 0.95;
}

.iot-why-choose-us::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 37%;
    width: 100%;
    background: #ffffff;
    z-index: -1;
}

.single-iot-box {
    text-align: center;
    background-color: #ffffff;
    border: 1px dashed #cdf1d8;
    padding: 30px;
    position: relative;
    z-index: 1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-iot-box h3 {
    font-size: 18px;
    margin-top: 22px;
    margin-bottom: 17px;
}

.single-iot-box p {
    margin-bottom: 25px;
}

.single-iot-box a {
    width: 40px;
    background-color: #eef0f3;
    height: 40px;
    line-height: 40px;
    color: #0e314c;
    border-radius: 50%;
}

.single-iot-box a svg {
    width: 18px;
}

.single-iot-box .icon {
    display: inline-block;
    border: 1px solid #f3f8fd;
    width: 100px;
    height: 100px;
    line-height: 96px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

.single-iot-box .icon::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    background: #f7fafd;
    border-radius: 50%;
    margin: 6px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-iot-box .icon i::before {
    font-size: 35px;
}

.single-iot-box .icon img {
    width: 45px;
}

.single-iot-box:hover, .single-iot-box:focus {
    border-color: #44ce6f;
}

.single-iot-box:hover a, .single-iot-box:focus a {
    background-color: #44ce6f;
    color: #ffffff;
}

/*================================================
ML Main Banner CSS
=================================================*/

.ml-main-banner {
    position: relative;
    z-index: 1;
    padding-top: 130px;
    padding-bottom: 140px;
    background-image: url(../images/img/banner-bg.jpg);
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
}

.ml-main-banner .container-fluid {
    padding-left: 70px;
    padding-right: 70px;
}

.ml-banner-content h1 {
    margin-bottom: 15px;
    line-height: 1.3;
    font-size: 40px;
    font-weight: 500;
}

.ml-banner-content p {
    font-size: 15px;
    max-width: 490px;
}

.ml-banner-content .btn {
    margin-top: 5px;
}

.ml-banner-single-image {
    text-align: center;
}

.ml-main-section {
    position: relative;
    z-index: 1;
    padding-top: 260px;
    padding-bottom: 240px;
    background-image: url(../images/img/banner-bg.jpg);
    background-position: bottom right;
    background-size: cover;
    background-repeat: no-repeat;
}

.ml-main-section .ml-banner-content h1 {
    font-size: 37px;
}

.ml-main-section .ml-banner-content p {
    max-width: 100%;
}

.ml-banner-image {
    position: relative;
    width: 100%;
    height: 100%;
    top: 140px;
}

.ml-banner-image img {
    position: absolute;
}

.ml-banner-image img:nth-child(1) {
    left: 195px;
    top: -125px;
    z-index: 2;
}

.ml-banner-image img:nth-child(2) {
    top: -83px;
    left: 153px;
    z-index: 1;
}

.ml-banner-image img:nth-child(3) {
    top: 40px;
    left: 90px;
}

.ml-banner-image img:nth-child(4) {
    top: 0;
    right: 165px;
    z-index: 3;
}

.ml-banner-image img:nth-child(5) {
    top: -260px;
    left: 110px;
}

.ml-banner-image img:nth-child(6) {
    right: 130px;
    top: -240px;
}

.ml-banner-image img:nth-child(7) {
    bottom: 0;
    right: 0;
    z-index: 2;
}

.ml-banner-image img:nth-child(8) {
    right: 10px;
    bottom: 205px;
    z-index: 1;
}

.ml-banner-image img:nth-child(9) {
    left: 15px;
    top: -185px;
    z-index: 1;
}

.ml-banner-image img:nth-child(10) {
    left: 1px;
    bottom: -2px;
    z-index: 2;
}

.ml-banner-image img:nth-child(11) {
    left: 0;
    top: -20px;
}

.ml-banner-image img:nth-child(12) {
    display: none;
}

/*================================================
ML Partner CSS
=================================================*/

.ml-partner-area.mt-minus-top {
    margin-top: -80px;
}

.single-ml-partner {
    text-align: center;
}

.single-ml-partner a {
    display: block;
    position: relative;
}

.single-ml-partner a img {
    width: auto !important;
    display: inline-block !important;
}

.single-ml-partner a img:last-child {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}

.single-ml-partner a:hover img:last-child, .single-ml-partner a:focus img:last-child {
    opacity: 1;
    visibility: visible;
    top: 0;
}

/*================================================
Our Solutions CSS
=================================================*/

.solutions-area {
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
}

.solutions-area p {
    color: #141414;
    min-height: 210px;
}

.single-solutions-box {
    padding: 30px;
    text-align: center;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
    /*border: 1px solid /*#c03838#981914;*/
    border-radius: 5px;
}

.single-solutions-box .icon {
    /*margin-bottom: 25px;*/
    position: relative;
    z-index: 1;
    display: inline-block;
    height: 150px;
}

.single-solutions-box .icon img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-solutions-box .icon::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
    background: #f0fdfe;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.09);
    border-radius: 30% 70% 70% 30% / 40% 60% 40% 60%;
    width: 60px;
    height: 60px;
}

.single-solutions-box h3 {
    font-size: 18px;
    margin-bottom: 12px;
}

.single-solutions-box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    border-radius: 5px;
    height: 100%;
    background-color: #b4faff;
    background: -webkit-gradient(linear, left bottom, right top, from(#d5fefd), color-stop(#e0fdff), color-stop(#effbff), color-stop(#fbfaff), to(#fffcff));
    background: linear-gradient(to right top, #d5fefd, #e0fdff, #effbff, #fbfaff, #fffcff);
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.158);
}

.single-solutions-box .learn-more-btn {
    color: #b91817;
    font-size: 14px;
    font-weight: 600;
}

.single-solutions-box .learn-more-btn svg {
    width: 18px;
    position: relative;
    top: -1px;
    margin-right: 3px;
}

.single-solutions-box .learn-more-btn:hover {
    color:
        /*#c03838*/
        #981914;
}

.single-solutions-box:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.192);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.158);
}

.single-solutions-box:hover .icon img {
    -webkit-transform: rotateY(-360deg);
    transform: rotateY(-360deg);
}

/*================================================
ML About CSS
=================================================*/

.about-area {
    position: relative;
    z-index: 1;
}

.ml-about-img {
    text-align: center;
}

.ml-about-content {
    max-width: 540px;
}

.ml-about-content .sub-title {
    display: inline-block;
    color: #c679e3;
    background-color: #f5dffd;
    margin-bottom: 18px;
    border-radius: 30px;
    padding: 5px 20px 4px;
    font-size: 13px;
}

.ml-about-content h2 {
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
}

.ml-about-content .bar {
    height: 5px;
    width: 90px;
    background: #cdf1d8;
    margin: 20px 0;
    position: relative;
    border-radius: 30px;
}

.ml-about-content .bar::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #44ce6f;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}

.ml-about-content p {
    margin-bottom: 12px;
}

.ml-about-content .btn {
    margin-top: 10px;
}

/*================================================
ML Services CSS
=================================================*/

.ml-services-area {
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
    background: -webkit-gradient(linear, right top, left bottom, from(#fffcff), color-stop(#fbfaff), color-stop(#effbff), color-stop(#e0fdff), to(#d5fefd));
    background: linear-gradient(to left bottom, #fffcff, #fbfaff, #effbff, #e0fdff, #d5fefd);
}

.single-ml-services-box {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
    padding: 30px 25px;
    overflow: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-ml-services-box .image {
    margin-bottom: 25px;
}

.single-ml-services-box h3 {
    font-size: 18px;
    margin-bottom: 12px;
}

.single-ml-services-box p {
    margin-bottom: 0;
}

.single-ml-services-box::before {
    content: "";
    position: absolute;
    left: -15px;
    bottom: -15px;
    width: 30px;
    height: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background: #44ce6f;
    visibility: hidden;
    opacity: 0;
}

.single-ml-services-box:hover::before {
    opacity: 1;
    visibility: visible;
}

/*================================================
ML Feedback CSS
=================================================*/

.ml-feedback-area {
    background: -webkit-gradient(linear, left bottom, right top, from(#d5fefd), color-stop(#e0fdff), color-stop(#effbff), color-stop(#fbfaff), to(#fffcff));
    background: linear-gradient(to right top, #d5fefd, #e0fdff, #effbff, #fbfaff, #fffcff);
    position: relative;
    z-index: 1;
    padding-bottom: 50px;
}

.single-ml-feedback-item {
    -webkit-box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
    margin-bottom: 45px;
    background-color: #ffffff;
    position: relative;
    padding: 60px 70px 60px 190px;
    border-radius: 5px;
}

.single-ml-feedback-item .client-info {
    position: absolute;
    left: 60px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
}

.single-ml-feedback-item .client-info img {
    border-radius: 5px;
    border: 2px solid #f4f5fe;
    width: 80px !important;
    height: 80px !important;
    padding: 5px;
    display: inline-block !important;
}

.single-ml-feedback-item .client-info h3 {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 17px;
}

.single-ml-feedback-item .client-info span {
    display: block;
    color: #c679e3;
    margin-top: 5px;
    font-size: 13px;
}

.single-ml-feedback-item p {
    margin-bottom: 0;
    color: #4a6f8a;
}

.single-ml-feedback-item .rating {
    margin-top: 10px;
}

.single-ml-feedback-item .rating svg {
    color: #ff612f;
    width: 18px;
}

.single-ml-feedback-item::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px;
    bottom: -15px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    z-index: -1;
    margin-left: 30px;
    margin-right: 30px;
}

.ml-feedback-slides {
    max-width: 750px;
    margin: -40px auto 0;
}

.ml-feedback-slides .single-ml-feedback-item {
    margin-top: 40px;
}

.ml-feedback-slides .owl-item {
    padding-left: 50px;
    padding-right: 50px;
}

.ml-feedback-slides .owl-dots {
    margin-top: 10px;
    margin-bottom: 30px;
}

.ml-feedback-slides .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 0 3px;
    background: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 1px solid #8d95a7;
    border-radius: 50%;
    position: relative;
}

.ml-feedback-slides .owl-dots .owl-dot span::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #44ce6f;
    margin: 2px;
    content: "";
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.ml-feedback-slides .owl-dots .owl-dot:hover span, .ml-feedback-slides .owl-dots .owl-dot.active span {
    border-color: #44ce6f;
    background-color: transparent;
}

.ml-feedback-slides .owl-dots .owl-dot:hover span::before, .ml-feedback-slides .owl-dots .owl-dot.active span::before {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/*================================================
Free Trial CSS
=================================================*/

.free-trial-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.free-trial-area::before {
    content: "";
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    left: -30%;
    top: 0;
    background: -webkit-gradient(linear, left top, right top, from(#d5fefd), color-stop(#e1fdff), color-stop(#effdff), color-stop(#fafdff), to(#ffffff));
    background: linear-gradient(to right, #d5fefd, #e1fdff, #effdff, #fafdff, #ffffff);
}

.free-trial-area .shape6 {
    top: 75%;
}

.free-trial-image {
    text-align: center;
}

.free-trial-content {
    max-width: 540px;
}

.free-trial-content h2 {
    margin-bottom: 0;
    font-size: 28px;
    font-weight: 600;
}

.free-trial-content form {
    position: relative;
    margin-top: 25px;
    margin-bottom: 10px;
}

.free-trial-content form .input-newsletter {
    display: block;
    width: 99%;
    height: 50px;
    color: #ffffff;
    border: none;
    outline: 0;
    background-color: #0e314c;
    border-radius: 30px;
    padding-top: 3px;
    padding-left: 20px;
}

.free-trial-content form .input-newsletter::-webkit-input-placeholder {
    color: #d5d5d5;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.free-trial-content form .input-newsletter:-ms-input-placeholder {
    color: #d5d5d5;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.free-trial-content form .input-newsletter::-ms-input-placeholder {
    color: #d5d5d5;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.free-trial-content form .input-newsletter::placeholder {
    color: #d5d5d5;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.free-trial-content form .input-newsletter:focus::-webkit-input-placeholder {
    color: transparent;
}

.free-trial-content form .input-newsletter:focus:-ms-input-placeholder {
    color: transparent;
}

.free-trial-content form .input-newsletter:focus::-ms-input-placeholder {
    color: transparent;
}

.free-trial-content form .input-newsletter:focus::placeholder {
    color: transparent;
}

.free-trial-content form button {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    cursor: pointer;
    border: none;
    padding: 0 30px;
    line-height: 50px;
    background-color: #44ce6f;
    border-radius: 0 30px 30px 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: #ffffff;
    font-size: 16px;
}

.free-trial-content form button:hover {
    background-color: #c679e3;
    color: #ffffff;
}

.free-trial-content p {
    margin-bottom: 0;
}

/*================================================
ML Projects CSS
=================================================*/

.ml-projects-area {
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding-bottom: 50px;
}

.ml-projects-area .section-title {
    text-align: left;
    max-width: 530px;
}

.ml-projects-area .section-title h2 {
    max-width: 470px;
    line-height: 1.3;
}

.ml-projects-area .section-title .bar {
    margin-left: 0;
    margin-right: 0;
}

.ml-projects-area .container-fluid {
    position: relative;
}

.single-ml-projects-box {
    position: relative;
    border-radius: 5px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175);
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.175);
    background-color: #ffffff;
}

.single-ml-projects-box img {
    border-radius: 5px;
}

.single-ml-projects-box .plus-icon a {
    background-color: #44ce6f;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.4s ease-out 0s;
    transition: all 0.4s ease-out 0s;
    overflow: hidden;
    border-radius: 50%;
}

.single-ml-projects-box .plus-icon a span {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.single-ml-projects-box .plus-icon a span::before, .single-ml-projects-box .plus-icon a span::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background-color: #ffffff;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.single-ml-projects-box .plus-icon a span::after {
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.single-ml-projects-box .plus-icon a:hover {
    background-color: #c679e3;
}

.single-ml-projects-box:hover .plus-icon a {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.ml-projects-slides.owl-theme .owl-dots {
    position: absolute;
    right: 12.5%;
    top: -100px;
    z-index: 1;
    margin-top: 0;
}

.ml-projects-slides.owl-theme .owl-dots::before {
    content: "";
    position: absolute;
    left: -20px;
    top: -30px;
    background: -webkit-gradient(linear, right top, left bottom, from(#dee4f3), color-stop(#e7eaf6), color-stop(#f0f1f9), color-stop(#f8f8fc), to(#ffffff));
    background: linear-gradient(to left bottom, #dee4f3, #e7eaf6, #f0f1f9, #f8f8fc, #ffffff);
    border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
    width: 80px;
    height: 70px;
    z-index: -1;
}

.ml-projects-slides.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 0 3px;
    background: transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border: 1px solid #8d95a7;
    border-radius: 50%;
    position: relative;
}

.ml-projects-slides.owl-theme .owl-dots .owl-dot span::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #44ce6f;
    margin: 2px;
    content: "";
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.ml-projects-slides.owl-theme .owl-dots .owl-dot:hover span, .ml-projects-slides.owl-theme .owl-dots .owl-dot.active span {
    border-color: #44ce6f;
    background-color: transparent;
}

.ml-projects-slides.owl-theme .owl-dots .owl-dot:hover span::before, .ml-projects-slides.owl-theme .owl-dots .owl-dot.active span::before {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/*================================================
Agency Main Banner CSS
=================================================*/

.agency-main-banner {
    position: relative;
    z-index: 1;
}

.agency-main-banner .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.agency-main-banner .container-fluid .row {
    margin-left: 0;
    margin-right: 0;
}

.agency-main-banner .container-fluid .row .col-lg-6 {
    padding-left: 0;
    padding-right: 0;
}

.agency-main-banner .shape2 {
    left: 5%;
}

.agency-main-banner .shape8 {
    right: 50%;
}

.agency-main-banner .shape4 {
    right: 50%;
}

.agency-banner-content {
    margin-left: auto;
    max-width: 650px;
}

.agency-banner-content .sub-title {
    text-transform: uppercase;
    display: block;
    font-size: 16px;
    color: #6084a4;
    margin-bottom: 8px;
}

.agency-banner-content h1 {
    margin-bottom: 15px;
    line-height: 1.3;
    font-size: 50px;
    font-weight: 500;
}

.agency-banner-content p {
    font-size: 15px;
    max-width: 450px;
}

.agency-banner-content .btn {
    margin-top: 5px;
}

.agency-banner-image {
    text-align: right;
}

/*================================================
Our Solutions CSS
=================================================*/

.solutions-area .shape2 {
    left: 5%;
}

.solutions-box {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}

.solutions-box .icon {
    color: #44ce6f;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    padding-left: 20px;
}

.solutions-box .icon i {
    font-size: 45px;
}

.solutions-box .icon img {
    position: absolute;
    left: 0;
    z-index: -1;
    top: -25px;
}

.solutions-box h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.solutions-box .learn-more-btn {
    color: #365267;
    font-size: 14px;
    font-weight: 600;
}

.solutions-box .learn-more-btn svg {
    width: 18px;
    position: relative;
    top: -1px;
    margin-right: 3px;
}

.solutions-box .learn-more-btn:hover {
    color: #44ce6f;
}

/*================================================
Agency About CSS
=================================================*/

.agency-about-area {
    position: relative;
    z-index: 1;
}

.agency-about-area .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.agency-about-area .container-fluid .row {
    margin-left: 0;
    margin-right: 0;
}

.agency-about-area .container-fluid .row .col-lg-6 {
    padding-left: 0;
    padding-right: 0;
}

.agency-about-img {
    padding-right: 100px;
}

.agency-about-img-right {
    padding-left: 100px;
}

.agency-about-img img {
    width: 100%;
}

.agency-about-content {
    max-width: 540px;
}

.agency-about-content .sub-title {
    display: inline-block;
    color: #c679e3;
    background-color: #f5dffd;
    margin-bottom: 18px;
    border-radius: 30px;
    padding: 5px 20px 4px;
    font-size: 13px;
}

.agency-about-content h2 {
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 28px;
    font-weight: 600;
    text-transform: capitalize;
}

.agency-about-content .bar {
    height: 5px;
    width: 90px;
    background: #cdf1d8;
    margin: 20px 0;
    position: relative;
    border-radius: 30px;
}

.agency-about-content .bar::before {
    content: "";
    position: absolute;
    left: 0;
    top: -2.7px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #44ce6f;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: MOVE-BG;
    animation-name: MOVE-BG;
}

.agency-about-content p {
    margin-bottom: 12px;
}

.agency-about-content .btn {
    margin-top: 10px;
}

.shape10 {
    position: absolute;
    right: 0;
    top: -40%;
    z-index: -1;
}

/*================================================
Agency Services CSS
=================================================*/

.agency-services-area {
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
    background: -webkit-gradient(linear, left bottom, left top, from(#d5fefd), color-stop(#e1fdff), color-stop(#effdff), color-stop(#fafdff), to(#ffffff));
    background: linear-gradient(to top, #d5fefd, #e1fdff, #effdff, #fafdff, #ffffff);
}

.agency-services-box {
    margin-bottom: 30px;
    text-align: center;
}

.agency-services-box img {
    border-radius: 7px;
}

.agency-services-box .content {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: #ffffff;
    position: relative;
    border-radius: 7px;
    padding: 25px;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.05);
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -30px;
}

.agency-services-box .content h3 {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 20px;
    margin-bottom: 0;
}

.agency-services-box .content .read-more-btn {
    display: inline-block;
    border-radius: 30px;
    padding: 9px 25px;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    position: absolute;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: -22px;
    opacity: 0;
    visibility: hidden;
    font-size: 14px;
    font-weight: 500;
}

.agency-services-box .content .read-more-btn svg {
    width: 15px;
    margin-left: 2px;
}

.agency-services-box .content .read-more-btn:hover {
    background-color: #44ce6f;
    border-color: #44ce6f;
    color: #ffffff;
}

.agency-services-box:hover .content {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
}

.agency-services-box:hover .content h3 {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.agency-services-box:hover .content .read-more-btn {
    opacity: 1;
    visibility: visible;
}

/*================================================
Testimonials CSS
=================================================*/

.single-testimonials-item {
    -webkit-box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.04);
    margin-bottom: 45px;
    background-color: #ffffff;
    position: relative;
    padding: 110px 65px 60px;
    border-radius: 5px;
    text-align: center;
}

.single-testimonials-item p {
    margin-bottom: 0;
    color: #4a6f8a;
    font-size: 15px;
}

.single-testimonials-item .client-info {
    text-align: left;
    position: relative;
    padding-left: 95px;
    margin-top: 25px;
    max-width: 235px;
    margin-left: auto;
    margin-right: auto;
}

.single-testimonials-item .client-info img {
    border-radius: 5px;
    border: 2px solid #f4f5fe;
    width: 80px !important;
    height: 80px !important;
    padding: 5px;
    display: inline-block !important;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.single-testimonials-item .client-info .rating svg {
    color: #ff612f;
    width: 16px;
}

.single-testimonials-item .client-info h3 {
    margin-bottom: 0;
    margin-top: 8px;
    font-size: 17px;
}

.single-testimonials-item .client-info span {
    display: block;
    color: #c679e3;
    margin-top: 5px;
    font-size: 13px;
}

.single-testimonials-item::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 5px;
    bottom: -15px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.04);
    z-index: -1;
    margin-left: 30px;
    margin-right: 30px;
}

.single-testimonials-item::before {
    content: "\f11b";
    color: #d9dcde;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    text-align: center;
    font-family: Flaticon;
    font-style: normal;
    font-size: 65px;
}

.ml-feedback-slides .single-testimonials-item {
    margin-top: 40px;
}

.user-shape1 {
    position: absolute;
    z-index: -1;
    top: 12%;
    left: 10%;
    -webkit-animation: movebounce 9s linear infinite;
    animation: movebounce 9s linear infinite;
}

.user-shape2 {
    position: absolute;
    z-index: -1;
    left: 7%;
    -webkit-animation: moveleftbounce 7s linear infinite;
    animation: moveleftbounce 7s linear infinite;
    top: 47%;
}

.user-shape3 {
    position: absolute;
    z-index: -1;
    bottom: 12%;
    left: 15%;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
}

.user-shape4 {
    position: absolute;
    z-index: -1;
    top: 12%;
    right: 10%;
    -webkit-animation: movebounce 9s linear infinite;
    animation: movebounce 9s linear infinite;
}

.user-shape5 {
    position: absolute;
    z-index: -1;
    right: 7%;
    top: 45%;
    -webkit-animation: moveleftbounce 7s linear infinite;
    animation: moveleftbounce 7s linear infinite;
}

.user-shape6 {
    position: absolute;
    z-index: -1;
    bottom: 12%;
    right: 15%;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
}

.shape9 {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.shape9 img {
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
}

/*================================================
Agency CTA CSS
=================================================*/

.agency-cta-area {
    position: relative;
    z-index: 1;
    background-image: url(../images/img/agency-image/gradient-bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.agency-cta-content {
    text-align: center;
}

.agency-cta-content h2 {
    color: #ffffff;
    margin-bottom: 30px;
    font-size: 28px;
    font-weight: 600;
}

/*btn-gradient*/

.btn-gradient {
    color: #ffffff;
    background: -webkit-gradient(linear, left top, right top, color-stop(0, #ee0979), to(#ff6a00));
    background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    -webkit-transform: unset !important;
    transform: unset !important;
}

.btn-gradient::after {
    display: none;
}

.btn-gradient::before {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    background: linear-gradient(135deg, #23bdb8 0, #43e794 100%);
    left: 0;
    border-radius: 4px;
}

.btn-gradient:hover, .btn-gradient:focus, .btn-gradient:active {
    color: #ffffff;
    background: -webkit-gradient(linear, left top, right top, color-stop(0, #ee0979), to(#ff6a00));
    background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.btn-gradient:hover::before, .btn-gradient:focus::before, .btn-gradient:active::before {
    opacity: 1;
    visibility: visible;
    width: 100%;
}

.btn-gradient:not(:disabled):not(.disabled).active, .btn-gradient:not(:disabled):not(.disabled):active, .btn-gradient .show>.btn-primary.dropdown-toggle {
    color: #ffffff;
    background: -webkit-gradient(linear, left top, right top, color-stop(0, #ee0979), to(#ff6a00));
    background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.btn-gradient:not(:disabled):not(.disabled).active::before, .btn-gradient:not(:disabled):not(.disabled):active::before, .btn-gradient .show>.btn-primary.dropdown-toggle::before {
    opacity: 1;
    visibility: visible;
    width: 100%;
}

.btn-gradient.disabled, .btn-gradient:disabled {
    color: #ffffff;
    background: -webkit-gradient(linear, left top, right top, color-stop(0, #ee0979), to(#ff6a00));
    background: linear-gradient(90deg, #ee0979 0, #ff6a00 100%);
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.btn-gradient.disabled::before, .btn-gradient:disabled::before {
    opacity: 1;
    visibility: visible;
    width: 100%;
}

/*================================================
Agency Portfolio Main Banner CSS
=================================================*/

.agency-portfolio-main-banner {
    height: 100vh;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.agency-portfolio-main-banner.portfolio-banner-bg1 {
    background-image: url(../images/img/agency-portfolio-main-banner/img1.jpg);
}

.agency-portfolio-main-banner.portfolio-banner-bg2 {
    background-image: url(../images/img/agency-portfolio-main-banner/img2.jpg);
}

.agency-portfolio-main-banner.portfolio-banner-bg3 {
    background-image: url(../images/img/agency-portfolio-main-banner/img3.jpg);
}

.portfolio-banner-content {
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -50px;
}

.portfolio-banner-content .sub-title {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #6084a4;
    margin-bottom: 10px;
}

.portfolio-banner-content h1 {
    margin-bottom: 15px;
    line-height: 1.3;
    font-size: 40px;
    font-weight: 500;
}

.portfolio-banner-content p {
    font-size: 15px;
    max-width: 490px;
    margin-left: auto;
    margin-right: auto;
}

.portfolio-banner-content .btn {
    margin-top: 5px;
}

.agency-portfolio-home-slides.owl-theme .owl-nav {
    opacity: 0;
    visibility: hidden;
    margin-top: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.agency-portfolio-home-slides.owl-theme .owl-nav [class*="owl-"] {
    margin: 0;
    background-color: #e1e1e1;
    color: #0e314c;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 48px;
    position: absolute;
    left: 40px;
    top: 50%;
    border-radius: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 30px;
}

.agency-portfolio-home-slides.owl-theme .owl-nav [class*="owl-"]:hover {
    background-color: #44ce6f;
    color: #ffffff;
}

.agency-portfolio-home-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 40px;
}

.agency-portfolio-home-slides.owl-theme:hover .owl-nav {
    opacity: 1;
    visibility: visible;
}

/*================================================
Our Latest Projects CSS
=================================================*/

.our-latest-projects {
    padding-bottom: 50px;
    position: relative;
    z-index: 1;
}

.projects-items {
    margin-left: -15px;
    margin-right: -15px;
}

.projects-items .single-latest-projects {
    margin-bottom: 30px;
    position: relative;
}

.projects-items .single-latest-projects .link-btn {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
}

.projects-items .single-latest-projects .content {
    position: absolute;
    left: -5%;
    top: 60%;
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-top: 20px;
}

.projects-items .single-latest-projects .content span {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
}

.projects-items .single-latest-projects .content h3 {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 20px;
}

.projects-items .single-latest-projects:hover .content {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
}

.shorting-menu {
    text-align: right;
}

.shorting-menu button {
    background-color: transparent;
    cursor: pointer;
    color: #848484;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    margin-right: 15px;
    padding: 0;
    outline: 0;
    border: none;
    font-size: 14px;
    font-weight: 600;
}

.shorting-menu button:hover, .shorting-menu button.active {
    color: #0e314c;
}

.shorting-menu button:last-child {
    margin-right: 0;
}

/*================================================
Agency Portfolio About CSS
=================================================*/

.agency-portfolio-about-area {
    background-color: #f4f4f4;
}

.single-text-box h3 {
    margin-bottom: 12px;
    margin-top: 25px;
    font-weight: 600;
    font-size: 22px;
}

.single-text-box .learn-more-btn {
    color: #365267;
    font-size: 14px;
    font-weight: 600;
}

.single-text-box .learn-more-btn svg {
    width: 18px;
    position: relative;
    top: -1px;
    margin-right: 3px;
}

.single-text-box .learn-more-btn:hover {
    color: #44ce6f;
}

/*================================================
Agency Portfolio Partner CSS
=================================================*/

.agency-portfolio-partner-area {
    background-color: #0e314c;
}

.single-agency-portfolio-partner {
    text-align: center;
}

.single-agency-portfolio-partner a {
    display: block;
}

.single-agency-portfolio-partner a img {
    width: auto !important;
    display: inline-block !important;
}

/*================================================
Creative Area CSS
=================================================*/

.creative-area {
    height: 650px;
    background-image: url(../images/img/creative-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.creative-area .container {
    position: relative;
}

.creative-area .d-table-cell {
    vertical-align: bottom;
}

.creative-inner-area {
    max-width: 600px;
    margin-left: auto;
}

.creative-inner-area .single-counter {
    background-color: #0e314c;
    padding: 60px;
}

.creative-inner-area .single-counter h3 {
    color: #ffffff;
    margin-bottom: 5px;
    font-size: 32px;
    font-weight: 700;
}

.creative-inner-area .single-counter h3 .odometer-formatting-mark {
    display: none;
}

.creative-inner-area .single-counter p {
    margin-bottom: 0;
    line-height: initial;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
}

.creative-inner-area .row {
    margin-left: 0;
    margin-right: 0;
}

.creative-inner-area .col-lg-6 {
    padding-left: 0;
    padding-right: 0;
}

.creative-inner-area .col-lg-6:nth-child(1) .single-counter {
    background-color: #44ce6f;
}

.creative-inner-area .col-lg-6:nth-child(2) .single-counter {
    background-color: #0e314c;
}

/*================================================
Footer CSS
=================================================*/

.footer-area {
    padding-top: 80px;
    position: relative;
    z-index: 1;
    /*background-image: linear-gradient(#000000,#000000);*/
    background-color: #242424;
}

.redSigma {
    color: #bc2424;
}

.red2Sigma {
    color:
        /*#c03838*/
        #981914;
}

.red3Sigma {
    color: #cc4d4d;
}

.graySigma {
    color: #4c4c4c;
}

.gray2Sigma {
    color: #9e9e9e;
}

.gray3Sigma {
    color: #d1d1d1;
}

.whiteSigma {
    color: #ffffff;
}

.footer-area .map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: -1;
    bottom: 0;
    opacity: 0.7;
}

.single-footer-widget .logo {
    margin-bottom: 20px;
}

.single-footer-widget .logo a {
    display: block;
}

.single-footer-widget h3 {
    font-size: 18px;
    margin-bottom: 30px;
    color: #b9b9b9;
}

.single-footer-widget ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.single-footer-widget ul.list li {
    margin-bottom: 10px;
}

.single-footer-widget ul.list li a {
    color: #ffffff;
    letter-spacing: 0.1em;
}

.single-footer-widget ul.list li a:hover {
    color:
        /*#c03838*/
        #ff7676;
    padding-left: 7px;
    font-size: 15px;
}

.single-footer-widget ul.list li:last-child {
    margin-bottom: 0;
}

.single-footer-widget ul.footer-contact-info {
    margin-bottom: 20px;
}

.single-footer-widget ul.footer-contact-info li {
    position: relative;
    color: #ffffff;
    margin-bottom: 8px;
    padding-left: 25px;
}

.single-footer-widget ul.footer-contact-info li svg {
    position: absolute;
    left: 0;
    width: 15px;
    top: 0;
}

.single-footer-widget ul.footer-contact-info li:last-child {
    margin-bottom: 0;
}

.single-footer-widget ul.footer-contact-info li a {
    color: #ffffff;
}

.single-footer-widget ul.footer-contact-info li a:hover {
    color: #ff7676;
    font-size: 15px;
}

.single-footer-widget ul.social-links li {
    display: inline-block;
    margin-right: 2px;
}

.single-footer-widget ul.social-links li a {
    width: 40px;
    text-align: center;
    line-height: 29px;
    height: 40px;
    border: 1px solid
        /*#c03838*/
        #ff7676;
    border-radius: 50%;
    color: #44ce6f;
}

.single-footer-widget ul.social-links li a svg {
    margin-top: 6px;
    width: 30px;
}

.single-footer-widget ul.social-links li a.facebook {
    border-color:
        /*#c03838*/
        #b91817;
    color:
        /*#c03838*/
        #b91817;
}

.single-footer-widget ul.social-links li a.facebook:hover {
    background:
        /*#c03838*/
        #b91817;
    color: #ffffff;
}

.single-footer-widget ul.social-links li a.twitter {
    border-color:
        /*#c03838*/
        #b91817;
    color:
        /*#c03838*/
        #b91817;
}

.single-footer-widget ul.social-links li a.twitter:hover {
    background:
        /*#c03838*/
        #b91817;
    color: #ffffff;
}

.single-footer-widget ul.social-links li a.linkedin {
    border-color:
        /*#c03838*/
        #b91817;
    color:
        /*#c03838*/
        #b91817;
}

.single-footer-widget ul.social-links li a.linkedin:hover {
    background:
        /*#c03838*/
        #b91817;
    color: #ffffff;
}

/*solo para whatsapp*/

#whatsapp_icon {
    border-color: #b91817;
    fill: #b91817;
}

a.linkedin:hover #whatsapp_icon {
    transition: 0.5s;
    background: #b91817;
    fill: #ffffff;
}

.single-footer-widget ul.social-links li a.instagram {
    border-color:
        /*#c03838*/
        #b91817;
    color:
        /*#c03838*/
        #b91817;
}

.single-footer-widget ul.social-links li a.instagram:hover {
    background:
        /*#c03838*/
        #b91817;
    color: #ffffff;
}

.single-footer-widget ul.social-links li a:hover {
    background: #b91817;
    color: #b91817;
}

.copyright-area {
    margin-top: 80px;
    border-top: 1px solid #b91817;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

/*=============================
Go Top CSS
===============================*/

.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 30px;
    right: 0;
    color: #ffffff;
    background: #b91817;
    z-index: 1;
    display: none;
    width: 45px;
    text-align: center;
    height: 45px;
    border-radius: 10px 0 0 10px;
    line-height: 46px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.go-top:hover {
    color: #ffe2e2;
    background: #d14444;
}

/*# sourceMappingURL=style.css.map */

.about-content, .formContainer {
    padding: 30px;
    /*background-color: rgba(0, 0, 0, 0.835);*/
    border-radius: 20px;
}

.about-content h2 {
    color: #981814;
}

.encabezado-titulo, .formContainer p, .formPerfil p {
    color: white;
}

.about-content p, .formContainer p {
    font-weight: bold;
    color: #474747;
}

.encabezado, .encabezadoDescripcion {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color:
        /*#c03838*/
        #981814;
    /* margin-top: 40px;*/
    height: auto;
    width: 100%;
}

.encabezadoDescripcion {
    padding-top: 20px;
    padding-bottom: 20px;
    /* border-radius: 8px;*/
}

.contenedorCarousel .carouselFrag {
    width: 100%;
}

.encabezado-titulo {
    font-size: 3vw;
}

#login .formContainer {
    margin-top: 60px;
    max-width: 500px;
}

#login h2 {
    text-align: center;
    color: #b91817;
}

.imagen {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 150px;
}

.imagenCarousel {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 600px;
}

.carritoCompras {
    padding: 30px;
    background-color: #ffffff;
    border-radius: 20px;
}

.carritoCompras .dataTables_filter, .pagination {
    padding-top: 10px;
    float: right;
}

.carritoCompras .dataTables_length .form-control {
    max-width: 40px;
}

.carritoCompras .dataTables_length .form-control option:focus {
    color: #fff;
    background-color: #bc2424;
    border-color: #bc2424;
}

/*.carritoCompras .dataTables_length .form-control option{


}*/

/*.carritoCompras .pagination .page-item .page-link{

}*/
.carritoCompras .pagination .page-item.active .page-link {
    color: #fff !important;
    background-color: #bc2424 !important;
    border-color: #bc2424 !important;
}

.carritoCompras .pagination .page-item .page-link {
    color: #bc2424 !important;
    background-color: #fff !important;
    border-color: #bc2424 !important;
}

.carritoCompras .pagination .page-item .page-link:hover {
    color: #fff !important;
    background-color: #bc6060 !important;
    border-color: #bc6060 !important;
}

.dropdownUser {
    display: inline-block;
}

.dropdownUser .dropdown-item {
    color: #bc2424;
}

.dropdownUser .dropdown-item:hover {
    transition: 300ms !important;
    background-color: #bc2424;
    color: #ffffff !important;
    cursor: pointer !important;
    font-size: 18px !important;
}

.dropdownUser .dropdown-item:hover a {
    color: #ffffff !important;
    transition: 300ms !important;
}

.contenedor-min {
    min-height: 90vh;
}

.zindex {
    z-index: 999998 !important;
}

.link-simple {
    cursor: pointer;
    color: #0e314c;
}

.link-simple:hover {
    color: #b91817;
}

/*modal*/

.modal-header {
    font-size: 30px;
    color: #bc2424;
    opacity: initial;
}

.modal-header .close {
    font-size: 30px;
    color: #bc2424;
    opacity: initial;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    transition: 1s;
    opacity: .75;
    color: #bc2424;
}

/**widget de bac, quitar btn cancelar/
/*  document.getElementById("card-ui-component-btn-cancel").style.display = "none";*/

#card-ui-component-btn-cancel {
    display: none;
}

.cardProductoCarrito {
    padding: 10px;
    background-color: white;
    margin-bottom: 15px;
    min-height: 300px;
    border-radius: 10px;
}

.cardProductoCarrito .btnIconCarrito, .cardPromocion .btnIconCarrito {
    border: none;
    padding: 5px;
    text-decoration: none;
    color:
        /*#c03838*/
        #981914;
    cursor: pointer;
    position: relative;
    /*  top: -1px;*/
    float: right;
    margin-left: 1px;
    border-radius: 20px;
    font-size: 16px;
}

.cardProductoCarrito .btnIconCarrito:hover, .cardPromocion .btnIconCarrito:hover {
    transition: 0.5s;
    color: #ffffff;
    border-radius: 20px;
    background-color:
        /*#c03838*/
        #981914;
}

.promoIcon {
    z-index: 6;
    display: inline-block;
    -webkit-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
    margin-bottom: -50px;
    margin-left: -30px;
}

.cardProductoCarrito a, .cardPromocion a .price, .products-details .cuenta {
    color: #981914;
    cursor: pointer;
}

.rojo {
    color: #ce2620;
}

.verde {
    color: #34f33a !important;
}

.cardPromocion {
    padding: 10px;
    background-color: white;
    margin-bottom: 15px;
    border-radius: 10px;
}

.sinbtn .cart-totals .btn-primary {
    display: none !important;
}

.botonesSecundarios {
    background-color: #bc2424 !important;
    color: #ffffff !important;
    margin-top: 0px;
    margin-bottom: 24px;
}

.dt-button {
    font-weight: 600;
    font-size: 12px;
    border: #ff4e4e9a;
    padding: 10px 20px;
}

.barraSeparadora {
    border: solid 2px #bc2424;
    border-radius: 40px;

}

.rojoVivo {
    color: #ff0000 !important;
}

.verdeVivo {
    color: #00ff08 !important;
}

.puntero {
    cursor: pointer;
}

.servicio-img {
    min-height: 162px
}

.irPerfil a {
    cursor: pointer;
    color: #b91817 ;
}
.irPerfil :hover {
    text-decoration: underline;
    text-decoration-color: #b91817;
    text-decoration-style: solid;
    
}